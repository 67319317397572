import { ShopAndSmileAPIAdapter } from '@bofrak-backend/shared';

import { envVars } from '../utils/constants';

const REST_API_URL = envVars.API_URL;

export const shopAndSmileAdapter = new ShopAndSmileAPIAdapter({
  apiEndpoint: REST_API_URL,
  retryCount: 0,
});

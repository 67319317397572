// useSingleTabBlocker.tsx
import { useEffect } from 'react';
import { ulid } from 'ulidx';

// Function to sanitize the location so it can be used as part of localStorage keys and channel names
const sanitizeLocation = (): string =>
  window.location.origin.replace(/[^a-zA-Z0-9]/g, '');

// Use the sanitized location as part of the channel name
const CHANNEL_NAME = `${sanitizeLocation()}-channel`;

const HEARTBEAT_INTERVAL = 1000; // ms between heartbeats
const HEARTBEAT_TIMEOUT = 3000; // if no heartbeat for 3 sec, consider that tab stale

// Blocking overlay functions
const createBlockingOverlay = () => {
  if (document.getElementById('tab-blocking-overlay')) return;

  const overlay = document.createElement('div');
  overlay.id = 'tab-blocking-overlay';
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
  overlay.style.color = 'white';
  overlay.style.display = 'flex';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.zIndex = '9999';
  overlay.style.fontSize = '1.5rem';
  overlay.style.textAlign = 'center';
  overlay.innerText = `Multiple tabs detected. Please keep only one tab open to use this application.`;
  document.body.appendChild(overlay);
};

const removeBlockingOverlay = () => {
  const overlay = document.getElementById('tab-blocking-overlay');
  if (overlay) {
    document.body.removeChild(overlay);
  }
};

type ChannelMessage = {
  type: 'primary-announcement' | 'heartbeat';
  tabId: string;
};

export const useSingleTabRestriction = () => {
  useEffect(() => {
    const channel = new BroadcastChannel(CHANNEL_NAME);
    // Generate (or reuse) a unique ID for this tab
    const tabId = sessionStorage.getItem('tabId') || ulid();
    sessionStorage.setItem('tabId', tabId);

    // Mutable ref-like variable to track if this tab is primary
    const isPrimaryRef = { current: false };

    // In-memory record of last heartbeat timestamps keyed by tabId
    const heartbeats: Record<string, number> = {};

    // Function to update the primary status based on received heartbeats
    const updatePrimaryStatus = () => {
      const now = Date.now();

      // Remove stale heartbeat entries
      for (const id in heartbeats) {
        if (now - heartbeats[id] > HEARTBEAT_TIMEOUT) {
          delete heartbeats[id];
        }
      }
      // If this tab is primary, update its own heartbeat
      if (isPrimaryRef.current) {
        heartbeats[tabId] = now;
      }

      // Gather active tab IDs
      const activeTabs = Object.keys(heartbeats);
      if (activeTabs.length === 0) {
        // No heartbeats exist, claim primary if not already
        if (!isPrimaryRef.current) {
          isPrimaryRef.current = true;
          channel.postMessage({ type: 'primary-announcement', tabId });
          removeBlockingOverlay();
        }
      } else {
        // Use tie-breaker: the tab with the smallest ID becomes primary
        const candidate = activeTabs.sort()[0];
        if (candidate === tabId) {
          if (!isPrimaryRef.current) {
            isPrimaryRef.current = true;
            channel.postMessage({ type: 'primary-announcement', tabId });
            removeBlockingOverlay();
          }
        } else {
          if (isPrimaryRef.current) {
            isPrimaryRef.current = false;
          }
          createBlockingOverlay();
        }
      }
    };

    // Listen for messages from other tabs
    channel.onmessage = (event) => {
      const message = event.data as ChannelMessage;
      if (message && message.tabId) {
        // Update the last heartbeat time for the sender
        heartbeats[message.tabId] = Date.now();
        // Reevaluate primary status on receiving any heartbeat or announcement
        updatePrimaryStatus();
      }
    };

    // Periodically send heartbeat if primary and update status
    const heartbeatInterval = setInterval(() => {
      if (isPrimaryRef.current) {
        channel.postMessage({ type: 'heartbeat', tabId });
      }
      updatePrimaryStatus();
    }, HEARTBEAT_INTERVAL);

    // Give some time on initial load to collect any messages
    const initialTimeout = setTimeout(() => {
      updatePrimaryStatus();
      if (!isPrimaryRef.current) {
        createBlockingOverlay();
      }
    }, HEARTBEAT_TIMEOUT + 100);

    // Cleanup when the tab is closed
    const cleanup = () => {
      clearInterval(heartbeatInterval);
      clearTimeout(initialTimeout);
      channel.close();
    };
    window.addEventListener('beforeunload', cleanup);

    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);
};

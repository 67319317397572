/**
 * This react hook accepts a phone number and a pin and returns a function that can be used to sign in and sign out a user.
 * It also returns the user's data and a boolean that indicates if the user is signed in. The data types come from @aws-sdk/client-cognito-identity-provider.
 */

import { MerchantUserAttributes } from '@bofrak-backend/shared';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useToast } from '@chakra-ui/react';
import { shopAndSmileAdapter } from '../api/api';
import {
  authAtom,
  employeeAtom,
  encryptedCredentialsAtom,
  isSignedInAtom,
  userAtom,
} from '../recoil/atoms';
import { envVars, paths } from '../utils/constants';

const CLIENT_ID = envVars.USER_POOL_CLIENT_ID;

interface UseAuthenticatorReturn {
  signIn: (phone_number: string, pin: string) => Promise<void>;
  isSignedIn: boolean;
  user: MerchantUserAttributes | null;
  signOut: () => Promise<void>;
  isLoading: boolean;
  error: string | null;
  encryptedCredentials: string | null;
}

export const useAuthenticator = (): UseAuthenticatorReturn => {
  const [isSignedIn, setIsSignedIn] = useRecoilState(isSignedInAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [error, setError] = useState<string | null>(null);
  const setEmployee = useSetRecoilState(employeeAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useRecoilState(authAtom);
  const [encryptedCredentials, setEncryptedCredentials] = useRecoilState(
    encryptedCredentialsAtom,
  );

  const navigate = useNavigate();
  const toast = useToast();

  const signIn = async (phone_number: string, pin: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const { encryptedCredentials, userData, employee, credentials } =
        await shopAndSmileAdapter.authenticateUser({
          phone_number,
          pin,
          client_id: CLIENT_ID,
        });

      setEmployee(employee);
      setUser(userData);
      setAuth({
        credentials,
        employee,
        encryptedCredentials,
        userData,
      });
      setEncryptedCredentials(encryptedCredentials);
      setIsSignedIn(true);

      toast({
        title: 'Sign in successful',
        description: 'You have successfully signed in',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      console.log('🚀 ~ signIn ~ error:', error);

      setError('Invalid phone number or pin');

      setIsSignedIn(false);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const signOutUser = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const signOutResponse = await shopAndSmileAdapter.signOutUser({
        accessToken: auth?.credentials.AccessToken || '',
      });

      console.log(
        '🚀 ~ file: use-authenticator.ts:102 ~ signOutUser ~ signOutResponse:',
        signOutResponse,
      );

      toast({
        title: 'Sign out successful',
        description: signOutResponse.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      const errorMessage =
        error.response?.data.message || 'An error occurred during sign out';

      setError(errorMessage);
    } finally {
      localStorage.clear();
      setIsSignedIn(false);
      setUser(null);
      setAuth(null);
      setEncryptedCredentials(null);
      setEmployee(null);
      setIsLoading(false);
      navigate(paths.home);
    }
  };

  return {
    signIn,
    isSignedIn,
    user,
    signOut: signOutUser,
    isLoading,
    error,
    encryptedCredentials,
  };
};

// Generated by ts-to-zod
import { z } from 'zod';
import {
  AuthType,
  CognitoAttributeDataType,
  SoftDeleteFlag,
  TransactionType,
  LoyaltyType,
  DebtOriginType,
  DebtStatus,
  EntityTypes,
  DebtEvent,
  PaymentMethodType,
  DebtCommandType,
  DiscountType,
  CalculateDiscountCommands,
  ShopAndSmileEventSource,
  ImageFileTypes,
  StringBoolean,
  InventoryTemplateStatus,
  InventoryChangeReason,
  ReasonCounted,
  InventoryChangeEvent,
  SortOrder,
  LoanStatus,
  LoanHistoryEvent,
  EmailCharset,
  PaymentReason,
  StoreProductChangeEvents,
  ProductChangeEvents,
  PromotionTypes,
  PromotionStatus,
  PurchaseStatus,
  PurchaseOrderPaymentStatus,
  UpdatePurchaseOrderCommand,
  ReasonMissing,
  ReceiptStatus,
  ReceiptHistoryItemType,
  ReceiptType,
  ConfirmationType,
  UpdateReceiptCommand,
  ReceiptEvents,
  SalesReportInterval,
  WebWorkerActions,
  GSI_DESCRIPTORS,
  RelationshipTypes,
  DefaultPricingType,
  Form,
  Color,
  ErrorCodes,
  UserRole,
  PaymentName,
  StoreEvents,
  TaxType,
  CalculateTaxCommands,
  UserEvents,
  StoreProductFraction,
  StoreProduct,
  StoreProductAttributes,
  StoreProductPage,
  StoreProductDBItem,
  StoreProductWebSocketMessage,
} from './../to-generate/to-generate';

export const authTypeSchema = z.nativeEnum(AuthType);

export const cognitoAttributeDataTypeSchema = z.nativeEnum(
  CognitoAttributeDataType,
);

export const cognitoAttributeDefinitionSchema = z.object({
  AttributeDataType: cognitoAttributeDataTypeSchema.optional(),
  Mutable: z.boolean(),
  Name: z.string(),
  Required: z.boolean().optional(),
});

export const merchantAuthStoreSchema = z.object({
  store_id: z.string(),
  store_name: z.string(),
  merchant_id: z.string(),
  roles: z.array(z.string()),
});

export const merchantAuthStoreWithRolesSchema = z.object({
  store_id: z.string(),
  roles: z.array(z.string()),
});

export const merchantUserAttributesSchema = z.object({
  username: z.string(),
  phone_number: z.string(),
  email: z.string(),
  address: z.string(),
  family_name: z.string(),
  given_name: z.string(),
  auth_type: authTypeSchema,
  business_name: z.string(),
  created_by: z.string(),
  pin: z.string(),
  stores: z.array(merchantAuthStoreSchema),
});

export const authenticateUserRequestSchema = z.object({
  phone_number: z.string(),
  pin: z.string(),
  client_id: z.string(),
});

export const decryptCredentialsRequestBodySchema = z.object({
  encryptedCredentials: z.string(),
});

export const signOutUserRequestBodySchema = z.object({
  accessToken: z.string(),
});

export const signOutUserResponseSchema = z.object({
  message: z.string(),
});

export const createMerchantUserRequestBodySchema = z.object({
  phone_number: z.string(),
  email: z.string().optional(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  auth_type: authTypeSchema,
  business_name: z.string(),
  created_by: z.string(),
  stores: z.array(merchantAuthStoreSchema),
  pin: z.string(),
});

export const createAdminUserRequestBodySchema = z.object({
  phone_number: z.string(),
  email: z.string(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  auth_type: authTypeSchema,
  password: z.string(),
});

export const updateAdminUserRequestBodySchema = z.object({
  user_id: z.string(),
  email: z.string(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  stores: z.array(merchantAuthStoreSchema).optional(),
});

export const createAuthUserResponseSchema = z.object({
  message: z.string(),
  userSub: z.string().optional(),
});

export const customerAuthStoreSchema = z.object({
  store_id: z.string(),
  store_name: z.string(),
  merchant_id: z.string(),
});

export const customerUserAttributesSchema = z.object({
  username: z.string(),
  phone_number: z.string(),
  email: z.string(),
  address: z.string(),
  family_name: z.string(),
  given_name: z.string(),
  gender: z.string(),
  profile_picture: z.string(),
  auth_type: authTypeSchema,
  created_by: z.string(),
  pin: z.string(),
  stores: z.array(customerAuthStoreSchema),
});

export const adminUserAttributesSchema = z.object({
  username: z.string(),
  phone_number: z.string(),
  email: z.string(),
  address: z.string(),
  family_name: z.string(),
  given_name: z.string(),
  auth_type: authTypeSchema,
});

export const updateMerchantUserRequestBodySchema = z.object({
  user_id: z.string(),
  email: z.string().optional(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  business_name: z.string().optional(),
  pin: z.string().optional(),
  add_to_stores: z.array(merchantAuthStoreSchema).optional(),
  remove_from_stores: z.array(merchantAuthStoreSchema).optional(),
  is_email_verified: z.boolean().optional(),
  add_to_roles: z.array(merchantAuthStoreWithRolesSchema).optional(),
  remove_from_roles: z.array(merchantAuthStoreWithRolesSchema).optional(),
});

export const createCustomerUserRequestBodySchema = z.object({
  phone_number: z.string(),
  email: z.string().optional(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  auth_type: authTypeSchema,
  created_by: z.string(),
  stores: z.array(merchantAuthStoreSchema),
  pin: z.string(),
  gender: z.string().optional(),
  profile_picture: z.string().optional(),
});

export const updateCustomerUserRequestBodySchema = z.object({
  user_id: z.string(),
  email: z.string().optional(),
  address: z.string().optional(),
  family_name: z.string().optional(),
  given_name: z.string().optional(),
  stores: z.array(customerAuthStoreSchema).optional(),
  pin: z.string().optional(),
  gender: z.string().optional(),
  profile_picture: z.string().optional(),
});

export const updateAuthUserResponseSchema = z.object({
  message: z.string(),
});

export const userAttributeSchema = z.object({
  Name: z.string(),
  Value: z.string(),
});

export const resetUserPasswordRequestBodySchema = z.object({
  user_id: z.string(),
  new_pin: z.string().optional(),
  auth_type: authTypeSchema,
  new_password: z.string().optional(),
});

export const principalSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  deleted_at: z.string(),
  updated_history: z.array(z.string()),
  merchant_id: z.string(),
  role_id: z.string(),
});

export const metaSchema = z.object({
  num_read: z.number(),
  next_cursor: z.string(),
});

export const policySchema = z.object({
  id: z.string(),
  policy: z.string(),
  action_id: z.string(),
  resource_id: z.string(),
  description: z.string(),
  created_at: z.string(),
  deleted_at: z.string(),
  updated_history: z.array(z.string()),
});

export const actionSchema = z.object({
  id: z.string(),
  description: z.string(),
  verb: z.string(),
  resource_id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string(),
  update_history: z.array(z.string()),
});

export const resourceSchema = z.object({
  id: z.string(),
  description: z.string(),
  path: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string(),
  update_history: z.string(),
});

export const actionListSchema = z.object({
  actions: z.array(actionSchema),
  meta: metaSchema,
});

export const resourceListSchema = z.object({
  resources: z.array(resourceSchema),
  meta: metaSchema,
});

export const policyListSchema = z.object({
  policies: z.array(policySchema),
  meta: metaSchema,
});

export const roleSchema = z.object({
  id: z.string(),
  description: z.string(),
  policies: z.array(z.string()),
  created_at: z.string(),
  resources: z.array(z.string()),
  deleted_at: z.string(),
  updated_history: z.array(z.string()),
  role_id: z.string(),
});

export const roleListSchema = z.object({
  roles: z.array(roleSchema),
  meta: metaSchema,
});

export const multiplePolicyListSchema = z.object({
  policies: z.array(policySchema),
  meta: z.object({
    items_created: z.number(),
  }),
});

export const policyBodySchema = z.object({
  action_id: z.string(),
  resource_id: z.string(),
  description: z.string(),
});

export const timestampsSchema = z.object({
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().optional().nullable(),
});

export const createCustomerSchema = z.object({
  name: z.string(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  customer_code: z.string().optional(),
  note: z.string().optional(),
  first_visit: z.string().optional().nullable(),
  last_visit: z.string().optional().nullable(),
  pin: z.string(),
  merchant_id: z.string(),
  id: z.string().optional(),
});

export const updateCustomerSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  customer_code: z.string().optional(),
  note: z.string().optional(),
  first_visit: z.string().optional().nullable(),
  last_visit: z.string().optional().nullable(),
  pin: z.string().optional(),
});

export const softDeleteFlagSchema = z.nativeEnum(SoftDeleteFlag);

export const getCustomersSchema = z.object({
  cursor: z.string().optional(),
  limit: z.number().optional(),
  merchant_id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const transactionTypeSchema = z.nativeEnum(TransactionType);

export const loyaltyTransactionItemSchema = z.object({
  product_id: z.string(),
  product_name: z.string(),
  price: z.number(),
  quantity: z.number(),
  eligible_for_points: z.boolean(),
});

export const loyaltyTransactionSchema = z.object({
  id: z.string(),
  customer_id: z.string(),
  type: transactionTypeSchema,
  items: z.array(loyaltyTransactionItemSchema),
  total_amount: z.number(),
  date: z.string(),
  merchant_id: z.string(),
});

export const loyaltyProductSchema = z.object({
  id: z.string(),
  name: z.string(),
  price: z.number(),
  eligible_for_points: z.boolean(),
  category: z.string().optional(),
  store_id: z.string(),
});

export const updateLoyaltyAfterSaleOrRefundSchema = z.object({
  customer_id: z.string(),
  total_spent: z.number(),
  visit_count: z.number(),
  merchant_id: z.string(),
  store_id: z.string(),
  points_earned: z.number(),
  points_deducted: z.number(),
});

export const redeemPointsSchema = z.object({
  merchant_id: z.string(),
  customer_id: z.string(),
  points_to_redeem: z.number(),
  store_id: z.string(),
});

export const redeemPointOutputSchema = z.object({
  transaction: loyaltyTransactionSchema,
  discount_amount: z.number(),
});

export const loyaltyTypeSchema = z.nativeEnum(LoyaltyType);

export const loyaltyProgramSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  loyalty_type: loyaltyTypeSchema,
  description: z.string(),
  points_per_threshold: z.number(),
  threshold_amount: z.number(),
  merchant_id: z.string(),
  store_id: z.string(),
  is_active: z.boolean(),
});

export const createLoyaltyProgramSchema = z.object({
  name: z.string(),
  loyalty_type: loyaltyTypeSchema,
  description: z.string(),
  points_per_threshold: z.number(),
  threshold_amount: z.number(),
  merchant_id: z.string(),
  is_active: z.boolean(),
  store_id: z.string(),
});

export const updateLoyaltyProgramSchema = z.object({
  id: z.string(),
  merchant_id: z.string(),
  store_id: z.string(),
  name: z.string().optional(),
  loyalty_type: loyaltyTypeSchema.optional(),
  description: z.string().optional(),
  points_per_threshold: z.number().optional(),
  threshold_amount: z.number().optional(),
  is_active: z.boolean().optional(),
});

export const loyaltyProgramDBItemSchema = loyaltyProgramSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const loyaltyProgramPageSchema = z.object({
  cursor: z.string().optional(),
  count: z.number().optional(),
  programs: z.array(loyaltyProgramSchema),
});

export const getLoyaltyProgramsSchema = z.object({
  cursor: z.string().optional(),
  limit: z.number().optional(),
  merchant_id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const debtOriginTypeSchema = z.nativeEnum(DebtOriginType);

export const debtStatusSchema = z.nativeEnum(DebtStatus);

export const entityTypesSchema = z.nativeEnum(EntityTypes);

export const paymentPlanSchema = timestampsSchema.extend({
  amount: z.number(),
  frequency: z.number(),
  store_id: z.string(),
  merchant_id: z.string(),
  note: z.string(),
});

export const debtEventSchema = z.nativeEnum(DebtEvent);

export const changedAttributesSchema = z.record(
  z.object({
    old: z.any(),
    new: z.any(),
  }),
);

export const getDebtChangesSchema = z.object({
  by_event: z.boolean(),
  by_event_and_debt: z.boolean(),
  by_event_and_date_range: z.boolean(),
  by_debt: z.boolean(),
  by_debt_and_date_range: z.boolean(),
  by_updator: z.boolean(),
  by_updator_and_date_range: z.boolean(),
  by_updator_and_debt: z.boolean(),
  event: debtEventSchema.optional(),
  debt_id: z.string().optional(),
  updator_id: z.string().optional(),
  updator_type: entityTypesSchema.optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  store_id: z.string().optional(),
  merchant_id: z.string(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
});

export const paymentMethodTypeSchema = z.nativeEnum(PaymentMethodType);

export const debtChangeEventSchema = z.object({
  changes: changedAttributesSchema,
  event: debtEventSchema,
});

export const createDebtCommandPayloadSchema = z.object({
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  creator_name: z.string(),
  debtor_id: z.string(),
  debtor_type: entityTypesSchema,
  debtor_name: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  origin_type: debtOriginTypeSchema,
  principal_amount: z.number(),
  interest_rate: z.number(),
  reason: z.string(),
  is_due_date_auto_calculated: z.boolean().optional(),
  initial_outstanding_balance: z.number().optional(),
  next_due_date: z.string().optional(),
  receipt_id: z.string().optional(),
  payment_plan: z
    .object({
      amount: z.number(),
      frequency: z.number(),
      note: z.string().optional(),
    })
    .optional(),
});

export const updateDebtStatusCommandPayloadSchema = z.object({
  debt_id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  updator_name: z.string(),
  status: debtStatusSchema,
});

export const cancelDebtCommandPayloadSchema = z.object({
  debt_id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  updator_name: z.string(),
  reason: z.string(),
});

export const transferDebtCommandPayloadSchema = z.object({
  from_debt_id: z.string(),
  to_debt_id: z.string(),
  amount: z.number(),
  note: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  creator_name: z.string(),
});

export const updateDateInterestRateCommandPayloadSchema = z.object({
  debt_id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  updator_name: z.string(),
  interest_rate: z.number(),
});

export const updateDebtPrincipalCommandPayloadSchema = z.object({
  debt_id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  updator_name: z.string(),
  principal_amount: z.number(),
});

export const addDebtPaymentCommandPayloadSchema = z.object({
  debt_id: z.string(),
  amount: z.number(),
  payment_type: paymentMethodTypeSchema,
  payer_id: z.string(),
  payer_type: entityTypesSchema,
  payer_name: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  user_id: z.string(),
  note: z.string().optional(),
});

export const updateDebtPaymentPlanCommandPayloadSchema = z.object({
  debt_id: z.string(),
  amount: z.number().optional(),
  frequency: z.number().optional(),
  note: z.string().optional(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  updator_name: z.string(),
});

export const debtCommandTypeSchema = z.nativeEnum(DebtCommandType);

export const debtCommandSchema = z.object({
  command: debtCommandTypeSchema,
  createDebtCommandPayload: createDebtCommandPayloadSchema.optional(),
  updateDebtStatusCommandPayload:
    updateDebtStatusCommandPayloadSchema.optional(),
  cancelDebtCommandPayload: cancelDebtCommandPayloadSchema.optional(),
  transferDebtCommandPayload: transferDebtCommandPayloadSchema.optional(),
  updateDateInterestRateCommandPayload:
    updateDateInterestRateCommandPayloadSchema.optional(),
  updateDebtPrincipalCommandPayload:
    updateDebtPrincipalCommandPayloadSchema.optional(),
  addDebtPaymentCommandPayload: addDebtPaymentCommandPayloadSchema.optional(),
  updateDebtPaymentPlanCommandPayload:
    updateDebtPaymentPlanCommandPayloadSchema.optional(),
});

export const getDebtAggregateSchema = z.object({
  debt_id: z.string(),
  merchant_id: z.string(),
  store_id: z.string(),
  change_cursor: z.string().optional(),
  payment_cursor: z.string().optional(),
  change_limit: z.number().optional(),
  payment_limit: z.number().optional(),
});

export const getDebtsSchema = z.object({
  by_store: z.boolean().optional(),
  by_store_and_date_range: z.boolean().optional(),
  by_store_and_status: z.boolean().optional(),
  by_store_and_status_and_date_range: z.boolean().optional(),
  by_store_and_origin: z.boolean().optional(),
  by_store_and_origin_and_date_range: z.boolean().optional(),
  by_creator: z.boolean().optional(),
  by_creator_and_date_range: z.boolean().optional(),
  by_debtor: z.boolean().optional(),
  by_debtor_and_store: z.boolean().optional(),
  by_debtor_and_date_range: z.boolean().optional(),
  by_merchant: z.boolean().optional(),
  by_merchant_and_date_range: z.boolean().optional(),
  by_receipt: z.boolean().optional(),
  store_id: z.string().optional(),
  merchant_id: z.string(),
  status: debtStatusSchema.optional(),
  origin: debtOriginTypeSchema.optional(),
  debtor_id: z.string().optional(),
  debtor_type: entityTypesSchema.optional(),
  creator_id: z.string().optional(),
  creator_type: entityTypesSchema.optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  receipt_id: z.string().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
});

export const getDebtPaymentsSchema = z.object({
  by_debt: z.boolean().optional(),
  by_debt_and_date_range: z.boolean().optional(),
  by_payer: z.boolean().optional(),
  by_payer_and_date_range: z.boolean().optional(),
  by_merchant: z.boolean().optional(),
  by_merchant_and_date_range: z.boolean().optional(),
  by_store: z.boolean().optional(),
  by_store_and_date_range: z.boolean().optional(),
  debt_id: z.string().optional(),
  payer_id: z.string().optional(),
  payer_type: entityTypesSchema.optional(),
  store_id: z.string().optional(),
  merchant_id: z.string(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
});

export const getDebtorSummarySchema = z.object({
  debtor_id: z.string(),
  debtor_type: entityTypesSchema,
  merchant_id: z.string(),
  store_id: z.string(),
});

export const discountTypeSchema = z.nativeEnum(DiscountType);

export const discountSchema = timestampsSchema.extend({
  id: z.string(),
  type: discountTypeSchema,
  name: z.string(),
  discount_amount: z.number().optional(),
  discount_percent: z.number().optional(),
  store_id: z.string(),
  restricted_access: z.boolean().optional(),
});

export const discountPageSchema = z.object({
  discounts: z.array(discountSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const createDiscountSchema = z.object({
  type: discountTypeSchema,
  name: z.string(),
  discount_amount: z.number().optional(),
  discount_percent: z.number().optional(),
  store_id: z.string(),
  restricted_access: z.boolean().optional(),
});

export const updateDiscountSchema = z.object({
  id: z.string(),
  type: discountTypeSchema.optional(),
  name: z.string().optional(),
  discount_amount: z.number().optional(),
  discount_percent: z.number().optional(),
  store_id: z.string(),
  restricted_access: z.boolean().optional(),
});

export const discountDBItemSchema = discountSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getDiscountByIdSchema = z.object({
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getDiscountsSchema = z.object({
  store_id: z.string(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const calculateDiscountCommandsSchema = z.nativeEnum(
  CalculateDiscountCommands,
);

export const calculateDiscountSchema = z.object({
  item_price: z.number(),
  discount_ids: z.array(z.string()),
  added_discount_id: z.string(),
  included_discount_ids: z.array(z.string()),
  command: calculateDiscountCommandsSchema,
});

export const calculateDiscountResultItemSchema = z.object({
  price: z.number(),
  finalPrice: z.number(),
  discount_value: z.number().optional(),
  discount_ids: z.array(z.string()),
});

export const calculateDiscountResultSchema = z.object({
  discount_command: calculateDiscountCommandsSchema,
  item_price: z.number().optional(),
  final_price: z.number().optional(),
  discount_value: z.number().optional(),
  total_discount: z.number().optional(),
  total_tax: z.number().optional(),
  items: z.array(calculateDiscountResultItemSchema).optional(),
});

export const shopAndSmileEventSourceSchema = z.nativeEnum(
  ShopAndSmileEventSource,
);

export const eventStoreKeysSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const timeRangeSchema = z.object({
  from: z.string(),
  to: z.string(),
});

export const imageSchema = timestampsSchema.extend({
  image_s3_url: z.string(),
  image_url: z.string(),
  image_s3_key: z.string(),
  entity_type: entityTypesSchema,
  entity_id: z.string(),
  image_id: z.string(),
  merchant_id: z.string(),
});

export const createImageSchema = z.object({
  image_id: z.string(),
  base64Image: z.string().optional(),
  entity_type: entityTypesSchema,
  entity_id: z.string(),
  merchant_id: z.string(),
  imageUrl: z.string().optional(),
});

export const imagePageSchema = z.object({
  images: z.array(imageSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const imageFileTypesSchema = z.nativeEnum(ImageFileTypes);

export const uploadImageResponseSchema = z.object({
  image_s3_key: z.string(),
  image_id: z.string(),
  file_name: z.string(),
});

export const uploadImageRequestSchema = z.object({
  entity_type: entityTypesSchema,
  entity_id: z.string(),
  updator_id: z.string(),
  store_id: z.string(),
  updator_type: entityTypesSchema,
});

export const uploadPromoImageRequestSchema = z.object({
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  updator_type: entityTypesSchema,
  base64Image: z.string(),
  item_id: z.string(),
});

export const stringBooleanSchema = z.nativeEnum(StringBoolean);

export const inventoryTemplateStatusSchema = z.nativeEnum(
  InventoryTemplateStatus,
);

export const inventoryTemplateSchema = timestampsSchema.extend({
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  counter_id: z.string(),
  counter_type: entityTypesSchema,
  status: inventoryTemplateStatusSchema,
  count_day: z.string(),
  id: z.string(),
  uncounted_items: z.array(z.string()),
  counted_items: z.array(z.string()),
  store_id: z.string(),
  title: z.string(),
  week_number: z.number(),
});

export const createInventoryTemplateSchema = z.object({
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  counter_id: z.string(),
  counter_type: entityTypesSchema,
  count_day: z.string(),
  uncounted_items: z.array(z.string()),
  store_id: z.string(),
  title: z.string(),
});

export const paginatedSchema = z.object({
  cursor: z.union([z.string(), z.undefined()]).optional().nullable(),
  count: z.number(),
});

export const getInventoryTemplateSchema = z.object({
  store_id: z.string(),
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const updateInventoryTemplateSchema = z.object({
  store_id: z.string(),
  counter_id: z.string().optional(),
  counter_type: entityTypesSchema.optional(),
  id: z.string(),
  status: inventoryTemplateStatusSchema.optional(),
  counted_items: z.array(z.string()).optional(),
  uncounted_items: z.array(z.string()).optional(),
  title: z.string().optional(),
  count_day: z.string().optional(),
});

export const deleteInventoryTemplateSchema = z.object({
  store_id: z.string(),
  id: z.string(),
});

export const getInventoryTemplatesSchema = z.object({
  by_count_day: z.boolean().optional(),
  by_status: z.boolean().optional(),
  by_counter: z.boolean().optional(),
  by_year_and_week: z.boolean().optional(),
  year: z.number().optional(),
  week: z.number().optional(),
  store_id: z.string().optional(),
  counter_id: z.string().optional(),
  status: inventoryTemplateStatusSchema.optional(),
  count_day: z.string().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const inventoryTemplateDBItemSchema = inventoryTemplateSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const inventoryChangeReasonSchema = z.nativeEnum(InventoryChangeReason);

export const reasonCountedSchema = z.nativeEnum(ReasonCounted);

export const inventoryChangeEventSchema = z.nativeEnum(InventoryChangeEvent);

export const supplierSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  contact: z.string().optional(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  website: z.string().optional(),
  address_1: z.string(),
  address_2: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  note: z.string().optional(),
  merchant_id: z.string(),
});

export const currentInventorySchema = timestampsSchema.extend({
  id: z.string(),
  store_id: z.string(),
  product_id: z.string(),
  cumulative_quantity: z.number(),
  merchant_id: z.string(),
  type: z.literal('CURRENT'),
  cost: z.number(),
  price: z.number(),
});

export const currentInventoryDBItemSchema = currentInventorySchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const sortOrderSchema = z.nativeEnum(SortOrder);

export const loanStatusSchema = z.nativeEnum(LoanStatus);

export const commonEmployeeAsCreatorAttributesSchema = z.object({
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  creator_name: z.string(),
});

export const loanHistoryEventSchema = z.nativeEnum(LoanHistoryEvent);

export const getLoansByCreatorDtoSchema = paginatedSchema.extend({
  creator_id: z.string(),
  by_creator_id: z.boolean().optional(),
  is_borrower_loan: z.boolean().optional(),
});

export const getLoansByBorrowerIdDtoSchema = paginatedSchema.extend({
  borrower_id: z.string(),
  by_borrower_id: z.boolean().optional(),
  is_borrower_loan: z.boolean().optional(),
});

export const getLoanByLoanIdDtoSchema = z.object({
  loan_id: z.string(),
  by_loan_id: z.boolean().optional(),
  by_borrower_loan_id: z.boolean().optional(),
  borrower_loan_id: z.string().optional(),
});

export const getLoansByStatusesDtoSchema = paginatedSchema.extend({
  statuses: z.array(loanStatusSchema),
  by_statuses: z.boolean().optional(),
  by_borrower_id_and_status: z.boolean().optional(),
  by_statuses_and_borrower_loan_id: z.boolean().optional(),
  is_borrower_loan: z.boolean().optional(),
  borrower_id: z.string().optional(),
  borrower_loan_id: z.string().optional(),
  borrower_type: entityTypesSchema.optional(),
});

export const createLoanDtoSchema =
  commonEmployeeAsCreatorAttributesSchema.extend({
    borrower_id: z.string(),
    borrower_type: entityTypesSchema,
    total_amount: z.number(),
    reason: z.string(),
  });

export const createLoanAfterFailureDtoSchema = z.object({
  creator: commonEmployeeAsCreatorAttributesSchema,
  borrower_id: z.string(),
  borrower_type: entityTypesSchema,
  total_amount: z.number(),
  reason: z.string(),
  previousEventId: z.string(),
});

export const updateLoanDtoSchema = z.object({
  borrower_id: z.string(),
  borrower_type: entityTypesSchema,
  status: loanStatusSchema,
});

export const currencySchema = z.object({
  code: z.string(),
  decimal_places: z.number(),
});

export const createMerchantSchema = z.object({
  business_name: z.string(),
  email: z.string(),
  country: z.string(),
  currency: currencySchema,
  phone_number: z.string(),
  address: z.string(),
  contact_person: z.string(),
  stores: z.array(z.string()),
});

export const updateMerchantSchema = z.object({
  business_name: z.string().optional(),
  email: z.string().optional(),
  country: z.string().optional(),
  currency: currencySchema.optional(),
  phone_number: z.string().optional(),
  address: z.string().optional(),
  contact_person: z.string().optional(),
  id: z.string(),
  add_stores: z.array(z.string()).optional(),
  remove_stores: z.array(z.string()).optional(),
});

export const emailCharsetSchema = z.nativeEnum(EmailCharset);

export const paymentTypeSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  payment_type: paymentMethodTypeSchema,
  stores: z.array(z.string()),
  merchant_id: z.string(),
});

export const createPaymentTypeSchema = z.object({
  name: z.string(),
  payment_type: paymentMethodTypeSchema,
  stores: z.array(z.string()),
  merchant_id: z.string(),
});

export const updatePaymentTypeSchema = z.object({
  name: z.string().optional(),
  payment_type: paymentMethodTypeSchema.optional(),
  add_stores: z.array(z.string()).optional(),
  remove_stores: z.array(z.string()).optional(),
  id: z.string(),
});

export const paymentTypePageSchema = z.object({
  payment_types: z.array(paymentTypeSchema),
  count: z.number(),
  cursor: z.string(),
});

export const getPaymentTypeSchema = z.object({
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getPaymentTypesSchema = z.object({
  cursor: z.string().optional(),
  limit: z.number().optional(),
  merchant_id: z.string(),
  stores: z.array(z.string()).optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const paymentTypeDBItemSchema = timestampsSchema
  .extend(paymentTypeSchema.shape)
  .extend({
    PK: z.string(),
    SK: z.string(),
    GSI1PK: z.string(),
    GSI1SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  });

export const paymentReasonSchema = z.nativeEnum(PaymentReason);

export const receiptPaymentSchema = timestampsSchema.extend({
  payment_type_id: z.string(),
  payment_type: paymentMethodTypeSchema,
  money_amount: z.number(),
  note: z.string().optional(),
});

export const refundReceiptPaymentDetailsSchema = z.object({
  receipt_id: z.string(),
  payment: receiptPaymentSchema,
  customer_id: z.string(),
  refund_for: z.string(),
});

export const loanPaymentDetailsSchema = z.object({
  borrower_id: z.string(),
  borrower_type: entityTypesSchema,
  borrower_loan_id: z.string(),
});

export const debtPaymentDetailsSchema = z.object({
  customer_id: z.string(),
  customer_debt_id: z.string(),
});

export const commonPaymentAttributesSchema = z.object({
  amount: z.number(),
  reason: paymentReasonSchema,
  employee: commonEmployeeAsCreatorAttributesSchema,
  note: z.string(),
  payer_id: z.string(),
  payer_type: entityTypesSchema,
  store_id: z.string(),
});

export const allocationSchema = timestampsSchema.extend({
  allocation_entity_type: entityTypesSchema,
  allocation_entity_id: z.string(),
  amount: z.number(),
});

export const createLoanPaymentDtoSchema = commonPaymentAttributesSchema.extend({
  borrower_loan_id: z.string(),
});

export const createDebtPaymentDtoSchema = commonPaymentAttributesSchema.extend({
  customer_debt_id: z.string(),
});

export const createRefundReceiptPaymentDtoSchema =
  commonPaymentAttributesSchema.extend(refundReceiptPaymentDetailsSchema.shape);

export const paginationParamsSchema = z.object({
  cursor: z.union([z.string(), z.undefined()]).optional().nullable(),
  limit: z.number().optional(),
});

export const getPaymentsByPayerAndDateRangeDtoSchema =
  paginationParamsSchema.extend({
    payer_id: z.string(),
    payer_type: entityTypesSchema,
    from: z.string(),
    to: z.string(),
  });

export const getPaymentsByCreatorDtoSchema = paginationParamsSchema.extend({
  creator_id: z.string(),
  creator_type: entityTypesSchema,
});

export const getPaymentsByOwnerDtoSchema = paginationParamsSchema.extend({
  owner_id: z.string(),
  owner_type: entityTypesSchema,
});

export const getPaymentByIdDtoSchema = z.object({
  payment_id: z.string(),
});

export const getPaymentsByEntityDtoSchema = paginationParamsSchema.extend({
  entity_id: z.string(),
  entity_type: entityTypesSchema,
});

export const dateRangeSchema = z.object({
  to: z.string(),
  from: z.string(),
});

export const getPaymentsByReasonDtoSchema = paginationParamsSchema.extend({
  reason: paymentReasonSchema,
});

export const getPaymentsByOwnerAndReasonDtoSchema =
  getPaymentsByOwnerDtoSchema.extend({
    reason: paymentReasonSchema,
  });

export const getPaymentsByOwnerAndDateRangeDtoSchema =
  getPaymentsByOwnerDtoSchema.extend(dateRangeSchema.shape);

export const getPaymentsByCreatorAndDateRangeDtoSchema =
  getPaymentsByCreatorDtoSchema.extend(dateRangeSchema.shape);

export const getPaymentsByDateRangeDtoSchema = paginationParamsSchema.extend(
  dateRangeSchema.shape,
);

export const updatePaymentAllocationsSchema =
  commonEmployeeAsCreatorAttributesSchema.extend({
    allocations: z.array(allocationSchema),
    payment_id: z.string(),
  });

export const posDeviceSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  store_id: z.string(),
  activated: z.boolean(),
  merchant_id: z.string(),
  prefix: z.string(),
  mac_address: z.string().optional(),
  current_user_id: z.string().optional(),
});

export const createPosDeviceSchema = z.object({
  name: z.string(),
  store_id: z.string(),
  activated: z.boolean(),
  merchant_id: z.string(),
  prefix: z.string(),
  mac_address: z.string().optional(),
});

export const updatePosDeviceSchema = z.object({
  name: z.string().optional(),
  activated: z.boolean().optional(),
  prefix: z.string().optional(),
  mac_address: z.string().optional(),
  merchant_id: z.string(),
  current_user_id: z.string().optional(),
  id: z.string(),
});

export const getPosDevicesSchema = z.object({
  store_id: z.string(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getPosDevicesByMerchantSchema = getPosDevicesSchema.extend({
  merchant_id: z.string(),
});

export const getPosDeviceSchema = z.object({
  id: z.string(),
  merchant_id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const posDeviceDBItemSchema = posDeviceSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const posDevicePageSchema = z.object({
  pos_devices: z.array(posDeviceSchema),
  count: z.number(),
  cursor: z.string().optional(),
});

export const storeSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  address: z.string().nullable(),
  city: z.string(),
  region: z.string().nullable(),
  postal_code: z.string().nullable(),
  country_code: z.string(),
  phone_number: z.string().nullable(),
  description: z.string().nullable(),
  merchant_id: z.string(),
  contact_person: z.string(),
  email: z.string().nullable(),
  contact_person_phone_number: z.string(),
  tax_number: z.string().nullable(),
  store_prefix: z.string(),
});

export const storeProductCostUpdatedEventDetailDataSchema = z.object({
  parentStoreProduct: z.any(),
});

export const productFractionSchema = timestampsSchema.extend({
  parent_id: z.string(),
  fraction_id: z.string(),
  fraction: z.number(),
  fraction_name: z.string(),
  parent_name: z.string(),
  merchant_id: z.string(),
});

export const getProductsSchema = z.object({
  merchant_id: z.string(),
  is_parent: z.boolean().optional(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const createProductSchema = z.object({
  creator_id: z.string(),
  name: z.string(),
  description: z.string(),
  image: z.string(),
  barcode: z.string(),
  quantity_in_unit_package: z.number(),
  weight_kg: z.number(),
  unit: z.string(),
  category_id: z.string().optional(),
  merchant_id: z.string(),
  unit_of_measurement: z.string(),
  taxes: z.array(z.string()),
});

export const updateProductSchema = z.object({
  id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  unit_of_measurement: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  image: z.string().optional(),
  barcode: z.string().optional(),
  quantity_in_unit_package: z.number().optional(),
  global_sku: z.number().optional(),
  weight_kg: z.number().optional(),
  unit: z.string().optional(),
  category_id: z.string().optional(),
  add_taxes: z.array(z.string()).optional(),
  remove_taxes: z.array(z.string()).optional(),
});

export const getStoreProductSchema = z.object({
  store_id: z.string(),
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getStoreProductByMerchantSchema = getStoreProductSchema.extend({
  merchant_id: z.string(),
});

export const getProductSchema = z.object({
  merchant_id: z.string(),
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getStoreProductsSchema = z.object({
  store_id: z.string(),
  is_parent: z.boolean().optional(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getStoreProductsByMerchantSchema = getStoreProductsSchema.extend({
  merchant_id: z.string(),
});

export const createStoreProductSchema = z.object({
  creator_id: z.string(),
  product_id: z.string(),
  store_id: z.string(),
  inventory: z.number(),
  is_available_for_sale: z.boolean(),
  is_sold_by_range: z.boolean(),
  max_selling_price: z.number(),
  min_selling_price: z.number(),
  is_manually_controlled: z.boolean(),
  is_sold_by_weight: z.boolean(),
  is_sold_online: z.boolean(),
  loyalty_programs: z.array(z.string()),
  price: z.number(),
  cost: z.number(),
  low_stock_threshold: z.number(),
  merchant_id: z.string(),
});

export const storeProductChangeEventsSchema = z.nativeEnum(
  StoreProductChangeEvents,
);

export const updateStoreProductSchema = z.object({
  id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_id: z.string(),
  inventory: z.number().optional(),
  is_available_for_sale: z.boolean().optional(),
  is_sold_by_range: z.boolean().optional(),
  max_selling_price: z.number().optional(),
  min_selling_price: z.number().optional(),
  is_manually_controlled: z.boolean().optional(),
  is_sold_by_weight: z.boolean().optional(),
  is_sold_online: z.boolean().optional(),
  add_loyalty_programs: z.array(z.string()).optional(),
  remove_loyalty_programs: z.array(z.string()).optional(),
  price: z.number().optional(),
  cost: z.number().optional(),
  low_stock_threshold: z.number().optional(),
  is_available_in_store: z.boolean().optional(),
  should_display_cost_on_pos: z.boolean().optional(),
});

export const updateStoreProductInventorySchema = z.object({
  id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  source_id: z.string(),
  source_type: entityTypesSchema,
  inventory: z.number(),
});

export const productFractionPageSchema = z.object({
  fractions: z.array(productFractionSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const productFractionDBItemSchema = productFractionSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getProductFractionsSchema = z.object({
  parent_id: z.string().optional(),
  fraction_id: z.string().optional(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getProductFractionsByMerchantSchema = z.object({
  merchant_id: z.string(),
  parent_id: z.string().optional(),
  fraction_id: z.string().optional(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getProductFractionSchema = z.object({
  merchant_id: z.string(),
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const createProductFractionSchema = z.object({
  fraction_id: z.string(),
  parent_id: z.string(),
  merchant_id: z.string(),
  creator_id: z.string(),
});

export const updateProductFractionSchema = z.object({
  id: z.string(),
  parent_id: z.string().optional(),
  fraction_name: z.string().optional(),
  parent_name: z.string().optional(),
  merchant_id: z.string(),
  updator_id: z.string(),
});

export const skuSchema = timestampsSchema.extend({
  global_sku: z.number(),
  merchant_id: z.string(),
  unique_by_merchant: z.string(),
  product_id: z.string(),
});

export const sKUDBItemSchema = skuSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const productChangeEventsSchema = z.nativeEnum(ProductChangeEvents);

export const productChangeSchema = timestampsSchema.extend({
  event: productChangeEventsSchema,
  version: z.string(),
  updator_id: z.string(),
  changed_attributes: changedAttributesSchema,
  change_reason: z.string(),
  id: z.string(),
  merchant_id: z.string(),
  updator_name: z.string(),
  product_name: z.string(),
});

export const productChangeDBItemSchema = productChangeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const productChangesPageSchema = z.object({
  changes: z.array(productChangeSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const getProductChangesSchema = z.object({
  by_user: z.boolean().optional(),
  by_user_and_date_range: z.boolean().optional(),
  by_user_and_product: z.boolean().optional(),
  by_user_product_and_date_range: z.boolean().optional(),
  by_user_and_event: z.boolean().optional(),
  by_user_and_product_and_event_and_date_range: z.boolean().optional(),
  by_product: z.boolean().optional(),
  by_merchant: z.boolean().optional(),
  by_merchant_and_product: z.boolean().optional(),
  by_merchant_and_date_range: z.boolean().optional(),
  by_merchant_product_and_date_range: z.boolean().optional(),
  by_merchant_and_event: z.boolean().optional(),
  by_merchant_event_and_date_range: z.boolean().optional(),
  by_merchant_event_and_product: z.boolean().optional(),
  by_merchant_event_product_and_date_range: z.boolean().optional(),
  by_product_and_version_range: z.boolean().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  merchant_id: z.string(),
  product_id: z.string().optional(),
  event: productChangeEventsSchema.optional(),
  updator_id: z.string().optional(),
  sort_order: z.union([z.literal('ASC'), z.literal('DESC')]).optional(),
  version: z.number().optional(),
  start_version: z.string().optional(),
  end_version: z.string().optional(),
});

export const getStoreProductChangesSchema = z.object({
  by_user: z.boolean().optional(),
  by_user_and_date_range: z.boolean().optional(),
  by_user_and_store_product: z.boolean().optional(),
  by_user_store_product_and_date_range: z.boolean().optional(),
  by_user_and_event: z.boolean().optional(),
  by_user_and_store_product_and_event: z.boolean().optional(),
  by_user_and_store_product_and_event_and_date_range: z.boolean().optional(),
  by_store_product: z.boolean().optional(),
  by_store: z.boolean().optional(),
  by_store_and_store_product: z.boolean().optional(),
  by_store_and_date_range: z.boolean().optional(),
  by_store_and_store_product_and_date_range: z.boolean().optional(),
  by_store_and_event: z.boolean().optional(),
  by_store_and_event_and_date_range: z.boolean().optional(),
  by_store_event_and_store_product: z.boolean().optional(),
  by_store_event_store_product_and_date_range: z.boolean().optional(),
  by_store_product_and_version_range: z.boolean().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  store_id: z.string().optional(),
  product_id: z.string().optional(),
  event: z
    .union([productChangeEventsSchema, storeProductChangeEventsSchema])
    .optional(),
  updator_id: z.string().optional(),
  sort_order: z.union([z.literal('ASC'), z.literal('DESC')]).optional(),
  version: z.number().optional(),
  start_version: z.number().optional(),
  end_version: z.number().optional(),
});

export const storeProductChangeSchema = timestampsSchema.extend({
  event: storeProductChangeEventsSchema,
  version: z.number(),
  updator_id: z.string(),
  changed_attributes: changedAttributesSchema,
  change_reason: z.string(),
  id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  updator_name: z.string(),
  product_name: z.string(),
});

export const storeProductChangeDBItemSchema = storeProductChangeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const bulkImportStoreProductSchema = z.object({
  store_id: z.string(),
  products: z.array(z.string()),
  merchant_id: z.string(),
  creator_id: z.string(),
  creator_type: entityTypesSchema,
});

export const bulkCreateStoreProductsResponseSchema = z.object({
  message: z.string(),
});

export const promotionTypesSchema = z.nativeEnum(PromotionTypes);

export const promotionStatusSchema = z.nativeEnum(PromotionStatus);

export const createPromotionRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  start_date: z.string(),
  end_date: z.string(),
  product_id: z.string(),
  image_url: z.string(),
  promotion_type: promotionTypesSchema,
  promotion_value: z.number(),
  promotion_code: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
});

export const updatePromotionRequestSchema = z.object({
  id: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  product_id: z.string().optional(),
  image_url: z.string().optional(),
  promotion_type: promotionTypesSchema.optional(),
  promotion_value: z.number().optional(),
  promotion_code: z.string().optional(),
  promotion_status: promotionStatusSchema.optional(),
  is_manual: z.boolean().optional(),
  store_id: z.string(),
  merchant_id: z.string(),
});

export const getPromotionsRequestSchema = z.object({
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
  cursor: z.string().optional(),
  by_status: z.boolean().optional(),
  by_start_date_range: z.boolean().optional(),
  by_start_date_range_and_status: z.boolean().optional(),
  by_end_date_range: z.boolean().optional(),
  by_end_date_range_and_status: z.boolean().optional(),
  by_item: z.boolean().optional(),
  by_item_and_status: z.boolean().optional(),
  item_id: z.string().optional(),
  status: promotionStatusSchema.optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  merchant_id: z.string(),
  store_id: z.string(),
});

export const promotionDiscountSchema = z.object({
  id: z.string(),
  type: discountTypeSchema,
  name: z.string(),
  discount_amount: z.number(),
  discount_percent: z.number(),
  stores: z.array(z.string()),
  restricted_access: z.boolean(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),
});

export const updatePromotionDiscountSchema = z.object({
  id: z.string(),
  type: discountTypeSchema,
  name: z.string(),
  discount_amount: z.number(),
  discount_percent: z.number(),
  stores: z.array(z.string()),
  restricted_access: z.boolean(),
});

export const createPromotionDiscountSchema = z.object({
  type: discountTypeSchema,
  name: z.string(),
  discount_amount: z.number(),
  discount_percent: z.number(),
  stores: z.array(z.string()),
  restricted_access: z.boolean(),
});

export const promotionDiscountPageSchema = z.object({
  discounts: z.array(promotionDiscountSchema),
  cursor: z.string(),
});

export const purchaseStatusSchema = z.nativeEnum(PurchaseStatus);

export const purchaseOrderPaymentStatusSchema = z.nativeEnum(
  PurchaseOrderPaymentStatus,
);

export const cancelPurchaseOrderItemSchema = z.object({
  id: z.string(),
  cancelled: z.number(),
});

export const purchaseOrderPaymentSchema = timestampsSchema.extend({
  id: z.string(),
  purchase_order_id: z.string(),
  amount_paid: z.number(),
  payment_location: z.string(),
  merchant_id: z.string(),
  store_id: z.string(),
  supplier_id: z.string(),
  amount_due: z.number(),
  total_paid: z.number(),
  total_amount: z.number(),
  creator_id: z.string(),
  creator_name: z.string(),
  refund_note: z.string().optional(),
});

export const createPurchaseOrderPaymentSchema = z.object({
  amount_paid: z.number(),
  payment_location: z.string(),
  merchant_id: z.string(),
  purchase_order_id: z.string(),
  store_id: z.string(),
  creator_id: z.string(),
  refund_note: z.string().optional(),
});

export const getAllPurchaseOrderPaymentsSchema = z.object({
  by_merchant: z.boolean().optional(),
  by_supplier: z.boolean().optional(),
  by_date: z.string().optional(),
  by_purchase_order: z.boolean().optional(),
  by_store: z.boolean().optional(),
  supplier_id: z.string().optional(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  merchant_id: z.string().optional(),
  purchase_order_id: z.string().optional(),
  store_id: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
});

export const purchaseOrderPaymentSummarySchema = z.object({
  total_money: z.number(),
  total_due: z.number(),
  total_paid: z.number(),
  count: z.number(),
});

export const purchaseOrderItemSchema = timestampsSchema.extend({
  id: z.string(),
  quantity: z.number(),
  cost: z.number(),
  received: z.number(),
  cancelled: z.number(),
  name: z.string(),
  in_stock: z.number(),
  received_at: z.string().optional(),
});

export const updatePurchaseOrderItemSchema = z.object({
  quantity: z.number(),
  id: z.string(),
  purchase_cost: z.number().optional(),
});

export const receivePurchaseOrderItemSchema = z.object({
  quantity: z.number(),
  id: z.string(),
  cost: z.number(),
});

export const createPurchaseOrderSchema = z.object({
  purchase_date: z.string(),
  ordered_by: z.string(),
  note: z.string(),
  supplier_id: z.string(),
  items: z.array(updatePurchaseOrderItemSchema),
  store_id: z.string(),
  merchant_id: z.string(),
});

export const updatePurchaseOrderCommandSchema = z.nativeEnum(
  UpdatePurchaseOrderCommand,
);

export const refundItemSchema = z.object({
  id: z.string(),
  amount: z.number(),
  merchant_id: z.string(),
  store_id: z.string(),
  updator_id: z.string(),
});

export const updatePurchaseOrderSchema = z.object({
  store_id: z.string(),
  id: z.string(),
  items: z.array(updatePurchaseOrderItemSchema),
  command: updatePurchaseOrderCommandSchema,
  cancellations: z.array(cancelPurchaseOrderItemSchema).optional(),
  refunds: z.array(refundItemSchema).optional(),
  updator_id: z.string(),
  note: z.string().optional(),
});

export const receivePurchaseOrderSchema = z.object({
  id: z.string(),
  items: z.array(receivePurchaseOrderItemSchema),
  creator_id: z.string(),
  store_id: z.string(),
});

export const getAllPurchaseOrdersSchema = z.object({
  by_merchant: z.boolean().optional(),
  by_supplier: z.boolean().optional(),
  by_date: z.boolean().optional(),
  by_payment_status: z.boolean().optional(),
  by_purchase_status: z.boolean().optional(),
  by_payment_status_and_date: z.boolean().optional(),
  by_purchase_status_and_date: z.boolean().optional(),
  by_store: z.boolean().optional(),
  by_store_and_date: z.boolean().optional(),
  supplier_id: z.string().optional(),
  merchant_id: z.string(),
  cursor: z.string().optional(),
  purchase_status: z.string().optional(),
  payment_status: z.string().optional(),
  store_id: z.string().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  limit: z.number().optional(),
});

export const getPurchaseOrderSchema = z.object({
  id: z.string(),
});

export const deletePurchaseOrderSchema = z.object({
  id: z.string(),
});

export const reasonMissingSchema = z.nativeEnum(ReasonMissing);

export const receiptStatusSchema = z.nativeEnum(ReceiptStatus);

export const missingItemSchema = z.object({
  quantity: z.number(),
  reason: reasonMissingSchema,
  employee_id: z.string(),
  employee_name: z.string(),
  item_id: z.string(),
});

export const cartItemSchema = timestampsSchema.extend({
  item_id: z.string(),
  item_name: z.string(),
  quantity: z.number(),
  missing: missingItemSchema,
  is_confirmed: z.boolean(),
});

export const employeeSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const receiptHistoryItemTypeSchema = z.nativeEnum(
  ReceiptHistoryItemType,
);

export const historyPaymentTypeSchema = z.object({
  payment_type_id: z.string(),
  payment_type_name: z.string(),
  payment_type: paymentMethodTypeSchema,
  amount: z.number(),
});

export const saleReceiptHistoryItemSchema = z.object({
  history_item_type: z.literal(ReceiptHistoryItemType.SALE),
  event: z.string(),
  value: z.number(),
  remaining_debt: z.number(),
  payments: z.array(historyPaymentTypeSchema),
  comment: z.string().optional(),
  date: z.string(),
  receipt_number: z.string(),
});

export const debtPaymentHistoryItemSchema = z.object({
  history_item_type: z.literal(ReceiptHistoryItemType.DEBT_PAYMENT),
  event: z.string(),
  paid_amount: z.number(),
  remaining_debt: z.number(),
  payment_id: z.string(),
  comment: z.string().optional(),
  date: z.string(),
  received_by: employeeSummarySchema,
});

export const refundReceiptHistoryItemSchema = z.object({
  history_item_type: z.literal(ReceiptHistoryItemType.REFUND),
  event: z.string(),
  refund_receipt_number: z.string(),
  refunded_payments: z.array(historyPaymentTypeSchema),
  amount_refunded: z.number(),
  comment: z.string().optional(),
  remaining_debt: z.number(),
  date: z.string(),
  refunded_by: employeeSummarySchema,
});

export const receiptHistoryUpdateCustomerItemSchema = z.object({
  history_item_type: z.literal(ReceiptHistoryItemType.UPDATE_CUSTOMER),
  event: z.string(),
  previous_customer_id: z.string(),
  new_customer_id: z.string(),
  date: z.string(),
  updated_by: employeeSummarySchema,
});

export const receiptHistoryCommentItemSchema = z.object({
  history_item_type: z.literal(ReceiptHistoryItemType.COMMENT),
  event: z.string(),
  comment: z.string(),
  date: z.string(),
  commented_by: employeeSummarySchema,
});

export const receiptDiscountSchema = discountSchema.extend({
  money_amount: z.number(),
});

export const confirmedQuantityTracebackSchema = timestampsSchema.extend({
  quantity: z.number(),
  confirmed_by: z.string(),
  note: z.string(),
});

export const missingItemTracebackSchema = timestampsSchema.extend({
  quantity: z.number(),
  reported_by: z.string(),
  note: z.string(),
});

export const receiptTypeSchema = z.nativeEnum(ReceiptType);

export const confirmationTypeSchema = z.nativeEnum(ConfirmationType);

export const commentSchema = timestampsSchema.extend({
  comment: z.string(),
  employee_id: z.string(),
  employee_name: z.string(),
});

export const clientSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  phone_number: z.string(),
  email: z.string().optional(),
  address: z.string().optional(),
  business_name: z.string().optional(),
});

export const customerUpdateTracebackSchema = timestampsSchema.extend({
  customer: z.string(),
  updator: z.string(),
  note: z.string(),
});

export const receiptFailedChecksSchema = z.object({
  note: z.string(),
  check_type: z.any(),
  created_at: z.string(),
});

export const thresholdSchema = timestampsSchema.extend({
  min_quantity: z.number(),
  min_total_money: z.number(),
  updated_by: z.array(employeeSummarySchema),
  store_id: z.string(),
  merchant_id: z.string(),
});

export const getReceiptsSchema = z.object({
  cursor: z.string().optional(),
  limit: z.number().optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  receipt_status: receiptStatusSchema.optional(),
  customer_id: z.string().optional(),
  store_id: z.string().optional(),
  confirmation_type: confirmationTypeSchema.optional(),
  pos_device_id: z.string().optional(),
  by_status: z.boolean().optional(),
  by_date_range: z.boolean().optional(),
  by_date_range_and_status: z.boolean().optional(),
  by_customer: z.boolean().optional(),
  by_customer_and_date_range: z.boolean().optional(),
  by_date_range_and_status_and_missing: z.boolean().optional(),
  by_store: z.boolean().optional(),
  by_store_and_date_range: z.boolean().optional(),
  by_store_and_status: z.boolean().optional(),
  by_store_and_date_range_and_status: z.boolean().optional(),
  by_store_and_confirmation_type: z.boolean().optional(),
  by_pos_device: z.boolean().optional(),
  sort_order: sortOrderSchema.optional(),
});

export const updateReceiptCommandSchema = z.nativeEnum(UpdateReceiptCommand);

export const updateReceiptSchema = z.object({
  receipt_number: z.string(),
  merchant_id: z.string(),
  command: updateReceiptCommandSchema,
  waiter: employeeSummarySchema.optional(),
  comments: z.array(commentSchema).optional(),
  receipt_status: z
    .object({
      receipt_status: receiptStatusSchema,
      updator: employeeSummarySchema,
    })
    .optional(),
  customer: z
    .object({
      customer_id: z.string(),
      updator_id: z.string(),
    })
    .optional(),
});

export const confirmReceiptSchema = z.object({
  receipt_number: z.string(),
  updator: employeeSummarySchema,
  confirmation_type: confirmationTypeSchema,
  command: updateReceiptCommandSchema,
  merchant_id: z.string(),
});

export const thresholdItemSchema = timestampsSchema
  .extend(thresholdSchema.shape)
  .extend({
    PK: z.string(),
    SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  });

export const createThresholdSchema = z.object({
  min_quantity: z.number(),
  min_total_money: z.number(),
  updated_by: employeeSummarySchema,
  store_id: z.string(),
  merchant_id: z.string(),
});

export const updateThresholdSchema = z.object({
  min_quantity: z.number().optional(),
  min_total_money: z.number().optional(),
  updated_by: employeeSummarySchema,
  store_id: z.string(),
  merchant_id: z.string(),
});

export const receiptEventsSchema = z.nativeEnum(ReceiptEvents);

export const salesReportSchema = timestampsSchema.extend({
  gross_sales: z.number(),
  refunds: z.number(),
  discounts: z.number(),
  cost_of_products: z.number(),
  expenses: z.number(),
  entity_type: entityTypesSchema,
  sale_receipt_count: z.number(),
  refund_receipt_count: z.number(),
  expense_count: z.number(),
  source_entity_id: z.string(),
  source_entity_type: entityTypesSchema,
  version: z.number(),
});

export const storeSalesReportSchema = salesReportSchema.extend({
  store_id: z.string(),
});

export const employeeSalesReportSchema = salesReportSchema.extend({
  employee_id: z.string(),
  employee_name: z.string(),
});

export const productSalesReportSchema = salesReportSchema.extend({
  product_id: z.string(),
  quantity: z.number(),
  product_name: z.string(),
  sku: z.string(),
});

export const salesReportDBItemSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const storeSalesReportDBItemSchema = storeSalesReportSchema.extend(
  salesReportDBItemSchema.shape,
);

export const employeeSalesReportDBItemSchema = employeeSalesReportSchema.extend(
  salesReportDBItemSchema.shape,
);

export const productSalesReportDBItemSchema = productSalesReportSchema.extend(
  salesReportDBItemSchema.shape,
);

export const salesReportExtensionsSchema = z.object({
  net_sales: z.number(),
  gross_profit: z.number(),
  net_profit: z.number(),
  gross_margin: z.number(),
  net_margin: z.number(),
});

export const getSalesReportSchema = z.object({
  merchant_id: z.string(),
  store_id: z.string(),
  products: z.array(z.string()).optional(),
  from: z.string().optional(),
  to: z.string().optional(),
  date: z.string().optional(),
  by_date: z.boolean().optional(),
  by_date_range: z.boolean().optional(),
  by_products_and_date: z.boolean().optional(),
  by_products_and_date_range: z.boolean().optional(),
});

export const getInventoryValueSchema = z.object({
  merchant_id: z.string(),
  store_id: z.string(),
  date: z.string().optional(),
});

export const getProductsInventoryValueSchema = z.object({
  merchant_id: z.string(),
  store_id: z.string(),
  date: z.string(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
});

export const productInventoryValueSchema = z.object({
  price: z.number(),
  cost: z.number(),
  inventory_value: z.number(),
  retail_value: z.number(),
  potential_profit: z.number(),
  margin: z.number(),
  created_at: z.string(),
  product_id: z.string(),
  product_name: z.string(),
  store_id: z.string(),
  in_stock: z.number(),
  merchant_id: z.string(),
});

export const productInventoryValuePageSchema = z.object({
  inventory_values: z.array(productInventoryValueSchema),
  count: z.number(),
  cursor: z.string().optional(),
});

export const storeInventoryValueSchema = z.object({
  store_id: z.string(),
  merchant_id: z.string(),
  inventory_value: z.number(),
  potential_profit: z.number(),
  retail_value: z.number(),
  margin: z.number(),
  created_at: z.string(),
});

export const merchantInventoryValueSchema = z.object({
  merchant_id: z.string(),
  merchant_name: z.string(),
  inventory_value: z.number(),
  potential_profit: z.number(),
  retail_value: z.number(),
  margin: z.number(),
  created_at: z.string(),
});

export const storeValueSnapshotDBItemSchema = storeInventoryValueSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const updateStoreInventorySQSMessageSchema = z.object({
  store_id: z.string(),
  merchant_id: z.string(),
});

export const salesReportIntervalSchema = z.nativeEnum(SalesReportInterval);

export const getSalesReportByDateRangeIntervalOptionsSchema = z.object({
  entityType: entityTypesSchema,
  entityId: z.string(),
  from: z.string(),
  to: z.string(),
  interval: salesReportIntervalSchema.optional(),
  is_cumulative: z.boolean().optional(),
});

export const webWorkerActionsSchema = z.nativeEnum(WebWorkerActions);

export const inventoryLevelSchema = z.object({
  variant_id: z.string(),
  store_id: z.string(),
  in_stock: z.number(),
  updated_at: z.string(),
  update_id: z.string().optional(),
});

export const inventoryLevelsOutputSchema = z.object({
  inventory_levels: z.array(inventoryLevelSchema),
});

export const inventoryLevelPropsSchema = z.object({
  variant_id: z.string(),
  store_id: z.string(),
  stock_after: z.number(),
});

export const inventoryLevelsPropsSchema = z.object({
  inventory_levels: z.array(inventoryLevelPropsSchema),
});

export const bofrakUploadImagePropsSchema = z.object({
  image_url: z.string(),
  item_id: z.string(),
});

export const bofrakOkResponseSchema = z.object({
  OK: z.boolean(),
  message: z.string().optional(),
});

export const backupDtoSchema = z.object({
  sku: z.string(),
  name: z.string(),
  price: z.number(),
  inventory: z.number(),
  image_s3_key: z.string(),
  old_image_url: z.string(),
  merchant_id: z.string(),
  trigger_image_backup: z.boolean(),
  new_image_url: z.string().optional(),
});

export const backupItemSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  EntityType: entityTypesSchema,
  sku: z.string(),
  name: z.string(),
  price: z.number(),
  inventory: z.number(),
  image_s3_key: z.string(),
  merchant_id: z.string(),
  old_image_url: z.string(),
  trigger_image_backup: z.boolean(),
  new_image_url: z.string().optional(),
});

export const merchantInfoSchema = z.object({
  id: z.string(),
  business_name: z.string(),
  email: z.string(),
  country: z.string(),
  currency: currencySchema,
  created_at: z.string(),
});

export const writeResponseSchema = z.object({
  message: z.string(),
  isSuccessful: z.boolean(),
});

export const gsiDescriptorsSchema = z.nativeEnum(GSI_DESCRIPTORS);

export const relationshipTypesSchema = z.nativeEnum(RelationshipTypes);

export const defaultPricingTypeSchema = z.nativeEnum(DefaultPricingType);

export const formSchema = z.nativeEnum(Form);

export const colorSchema = z.nativeEnum(Color);

export const componentSchema = z.object({
  variant_id: z.string(),
  quantity: z.number(),
});

export const updateAvailabilityPropsStoreSchema = z.object({
  store_id: z.string(),
  available_for_sale: z.boolean(),
});

export const updateAvailabilityPropsVariantSchema = z.object({
  variant_id: z.string(),
  item_id: z.string(),
  stores: z.array(updateAvailabilityPropsStoreSchema),
});

export const authUserSchema = z.union([
  customerUserAttributesSchema,
  merchantUserAttributesSchema,
  adminUserAttributesSchema,
]);

export const errorCodesSchema = z.nativeEnum(ErrorCodes);

export const totalMoneyRangeSchema = z.object({
  to: z.number(),
  from: z.number(),
});

export const eventBusDtoSchema = z.object({
  data: z.any(),
  metadata: z.record(z.unknown()),
});

export const uploadImageToItemPropsSchema = z.object({
  item_id: z.string(),
  image_url: z.string(),
});

export const connectionSchema = z.object({
  entityId: z.string(),
  entityType: entityTypesSchema,
  connectionId: z.string(),
  connected: z.boolean(),
  stage: z.string(),
  domainName: z.string(),
});

export const connectionItemSchema = connectionSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
});

export const sSMParameterConfigSchema = z.object({
  path: z.string(),
  outputKey: z.string(),
  description: z.string().optional(),
  secure: z.boolean().optional(),
});

export const userRoleSchema = z.nativeEnum(UserRole);

export const paymentNameSchema = z.nativeEnum(PaymentName);

export const userSchema = z.object({
  name: z.string(),
  surname: z.string(),
  phonenumber: z.string(),
  code: z.string(),
  user_id: z.string(),
  role: userRoleSchema,
  auth_type: authTypeSchema.optional(),
});

export const createUserPropsSchema = z.object({
  name: z.string(),
  surname: z.string(),
  phonenumber: z.string().optional(),
  code: z.string(),
  role: userRoleSchema,
  merchant_id: z.string(),
  stores: z.array(z.string()),
});

export const receiptQueryParamsSchema = paginationParamsSchema.extend({
  receipt_numbers: z.string().optional(),
  since_receipt_number: z.string().optional(),
  before_receipt_number: z.string().optional(),
  store_id: z.string().optional(),
  order: z.string().optional(),
  updated_at_min: z.string().optional(),
  updated_at_max: z.string().optional(),
  created_at_min: z.string().optional(),
  created_at_max: z.string().optional(),
});

export const userStoreSchema = z.object({
  merchant_id: z.string(),
  store_id: z.string(),
  user_id: z.string(),
});

export const cognitoStoresAttributeSchema = timestampsSchema.extend({
  stores: z.array(userStoreSchema),
});

export const storeEventsSchema = z.nativeEnum(StoreEvents);

export const storePageSchema = z.object({
  stores: z.array(storeSchema),
  count: z.number(),
  cursor: z.string().optional(),
});

export const createStoreSchema = z.object({
  name: z.string(),
  merchant_id: z.string(),
  contact_person: z.string(),
  contact_person_phone_number: z.string(),
  city: z.string(),
  address: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  phone_number: z.string().optional(),
  description: z.string().optional(),
  email: z.string().optional(),
  tax_number: z.string().optional(),
});

export const updateStoreSchema = z.object({
  name: z.string().optional(),
  contact_person: z.string().optional(),
  contact_person_phone_number: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  phone_number: z.string().optional(),
  description: z.string().optional(),
  email: z.string().optional(),
  id: z.string(),
  merchant_id: z.string(),
  tax_number: z.string().optional(),
});

export const storeDBItemSchema = storeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getStoresByMerchantDtoSchema = z.object({
  merchant_id: z.string(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const supplierPageSchema = z.object({
  cursor: z.string().optional(),
  count: z.number().optional(),
  suppliers: z.array(supplierSchema),
});

export const createSupplierSchema = z.object({
  name: z.string(),
  merchant_id: z.string(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  website: z.string().optional(),
  address_1: z.string().optional(),
  address_2: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  note: z.string().optional(),
  country_code: z.string().optional(),
  contact: z.string().optional(),
});

export const updateSupplierSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  website: z.string().optional(),
  address_1: z.string().optional(),
  address_2: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  note: z.string().optional(),
  country_code: z.string().optional(),
  contact: z.string().optional(),
});

export const supplierDBItemSchema = supplierSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getSupplierByIdSchema = z.object({
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getSuppliersSchema = z.object({
  merchant_id: z.string(),
  cursor: z.string().optional(),
  limit: z.number().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const taxTypeSchema = z.nativeEnum(TaxType);

export const createTaxSchema = z.object({
  name: z.string(),
  type: taxTypeSchema,
  rate: z.number(),
  merchant_id: z.string(),
});

export const updateTaxSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  type: taxTypeSchema.optional(),
  rate: z.number().optional(),
  merchant_id: z.string(),
});

export const getTaxByIdSchema = z.object({
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const getTaxesSchema = z.object({
  merchant_id: z.string(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const calculateTaxCommandsSchema = z.nativeEnum(CalculateTaxCommands);

export const calculateTaxSchema = z.object({
  item_price: z.number(),
  tax_ids: z.array(z.string()),
  added_tax_id: z.string(),
  included_tax_ids: z.array(z.string()),
  command: calculateTaxCommandsSchema,
});

export const calculateTaxResultSchema = z.object({
  command: calculateTaxCommandsSchema,
  item_price: z.number(),
  tax_value: z.number().nullable(),
  pre_tax_price: z.number().nullable(),
  total_tax: z.number().nullable(),
  added_tax: z.number().nullable(),
});

export const userEventsSchema = z.nativeEnum(UserEvents);

export const createUserSchema = z.object({
  name: z.string(),
  roles: z.array(z.string()),
  username: z.string(),
  email: z.string(),
  phone_number: z.string(),
  PIN: z.string(),
  stores: z.array(z.string()),
  merchant_id: z.string(),
});

export const employeeSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  roles: z.array(z.string()),
  username: z.string(),
  email: z.string(),
  phone_number: z.string(),
  stores: z.array(z.string()),
  merchant_id: z.string(),
  is_active: z.boolean(),
  is_inactive_since: z.string().optional(),
  cognito_id: z.union([z.string(), z.undefined()]).nullable(),
  cognito_username: z.union([z.string(), z.undefined()]).nullable(),
  is_owner: z.boolean(),
  auth_user: merchantUserAttributesSchema.optional(),
});

export const encryptCredentialsDtoSchema = z.object({
  AccessToken: z.string(),
  ExpiresIn: z.number(),
  Employee: employeeSchema,
  TokenType: z.string(),
});

export const principalListSchema = z.object({
  principals: z.array(principalSchema),
  meta: metaSchema,
});

export const multiplePolicyBodySchema = z.object({
  merchant_id: z.string(),
  policies: z.array(policyBodySchema),
});

export const customerSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postal_code: z.string().optional(),
  country_code: z.string().optional(),
  customer_code: z.string().optional(),
  note: z.string().optional(),
  first_visit: z.string().optional().nullable(),
  last_visit: z.string().optional().nullable(),
  total_visits: z.number(),
  total_spent: z.number(),
  total_points: z.number(),
  permanent_deletion_at: z.string().optional().nullable(),
  pin: z.string().optional(),
  merchant_id: z.string(),
  date_of_birth: z.string().optional(),
});

export const customerPageSchema = z.object({
  cursor: z.string().optional(),
  count: z.number().optional(),
  customers: z.array(customerSchema),
});

export const getCustomerSchema = z.object({
  id: z.string(),
  include_deleted: softDeleteFlagSchema.optional(),
});

export const customerDBItemSchema = customerSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const debtTransferSchema = timestampsSchema.extend({
  id: z.string(),
  from_debt_id: z.string(),
  to_debt_id: z.string(),
  store_id: z.string(),
  merchant_id: z.string(),
  note: z.string(),
  creator_id: z.string(),
  creator_name: z.string(),
  creator_type: entityTypesSchema,
  amount: z.number(),
});

export const debtSchema = timestampsSchema.extend({
  id: z.string(),
  creator_type: entityTypesSchema,
  creator_id: z.string(),
  creator_name: z.string(),
  debtor_id: z.string(),
  debtor_name: z.string(),
  debtor_type: entityTypesSchema,
  store_id: z.string(),
  merchant_id: z.string(),
  transfers: z.array(debtTransferSchema),
  origin_type: debtOriginTypeSchema,
  principal_amount: z.number(),
  outstanding_balance: z.number(),
  interest_rate: z.number(),
  status: debtStatusSchema,
  next_due_date: z.string(),
  closed_date: z.string().optional(),
  payment_plan: paymentPlanSchema,
  last_payment_date: z.string().optional(),
  is_due_date_auto_calculated: z.boolean(),
  reason: z.string(),
  receipt_id: z.string().optional(),
  version: z.number(),
  cancellation_reason: z.string().optional(),
  cancelled_at: z.string().optional(),
});

export const debtChangeSchema = timestampsSchema.extend({
  id: z.string(),
  event: debtEventSchema,
  version: z.string(),
  debt_version: z.number(),
  updator_id: z.string(),
  updator_name: z.string(),
  updator_type: entityTypesSchema,
  changed_attributes: changedAttributesSchema,
  store_id: z.string(),
  merchant_id: z.string(),
});

export const debtPaymentSchema = timestampsSchema.extend({
  id: z.string(),
  debt_id: z.string(),
  amount: z.number(),
  outstanding_balance: z.number(),
  initial_amount: z.number(),
  payment_type: paymentMethodTypeSchema,
  user_id: z.string(),
  payer_name: z.string(),
  payer_id: z.string(),
  payer_type: entityTypesSchema,
  store_id: z.string(),
  merchant_id: z.string(),
  note: z.string(),
});

export const debtDBItemSchema = debtSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string().optional(),
  GSI5SK: z.string().optional(),
});

export const debtChangeDBItemSchema = debtChangeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
});

export const debtPaymentDBItemSchema = debtPaymentSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
});

export const debtPaymentsPageSchema = z.object({
  payments: z.array(debtPaymentSchema),
  cursor: z.string(),
  count: z.number(),
});

export const debtChangesPageSchema = z.object({
  changes: z.array(debtChangeSchema),
  cursor: z.string(),
  count: z.number(),
});

export const debtsPageSchema = z.object({
  debts: z.array(debtSchema),
  cursor: z.string(),
  count: z.number(),
});

export const debtAggregateSchema = z.object({
  debt: debtSchema,
  changes: debtChangesPageSchema,
  payments: debtPaymentsPageSchema,
});

export const debtorSummarySchema = z.object({
  debtor_id: z.string(),
  debtor_type: entityTypesSchema,
  debtor_name: z.string(),
  total_money_debt: z.number(),
  total_money_payments: z.number(),
  total_money_outstanding: z.number(),
  total_money_overdue: z.number(),
  total_money_defaulted: z.number(),
  total_money_closed: z.number(),
  total_money_interest: z.number(),
  total_money_principal: z.number(),
  total_money_transferred_out: z.number(),
  total_money_transferred_in: z.number(),
  next_due_date: z.string(),
  stores: z.array(z.string()),
  overdue_count: z.number(),
  debt_count: z.number(),
  closed_count: z.number(),
  debts: z.array(debtAggregateSchema),
});

export const shopAndSmileEventMetadataSchema = z.object({
  correlation_id: z.string().optional(),
  causation_id: z.string(),
  event_id: z.string(),
  event_version: z.number(),
  time: z.string(),
  source: shopAndSmileEventSourceSchema,
  stage: z.string(),
  service: z.string(),
  tags: z.array(z.string()),
  creator_id: z.string(),
  creator_type: entityTypesSchema,
  creator_name: z.string(),
});

export const uploadItemImageRequestSchema = z.object({
  metadata: uploadImageRequestSchema,
  base64Image: z.string(),
});

export const imageMetadataSchema = z.record(z.string()).and(
  z.object({
    file_type: z.string(),
    entity_type: z.string(),
    entity_id: z.string(),
    image_id: z.string(),
    image_s3_key: z.string(),
    has_been_processed: stringBooleanSchema,
    updator_id: z.string(),
    updator_type: entityTypesSchema,
    store_id: z.string(),
    merchant_id: z.string(),
  }),
);

export const inventoryTemplatesPageSchema = paginatedSchema.extend({
  inventory_templates: z.array(inventoryTemplateSchema),
});

export const purchaseOrderDetailsSchema = z.object({
  id: z.string(),
  supplier: supplierSchema,
});

export const inventoryChangeSchema = timestampsSchema.extend({
  id: z.string(),
  store_id: z.string(),
  product_id: z.string(),
  product_name: z.string(),
  change_quantity: z.number(),
  cumulative_quantity: z.number(),
  reason: inventoryChangeReasonSchema,
  merchant_id: z.string(),
  created_at: z.string(),
  type: z.literal('CHANGE'),
  source_id: z.string(),
  source_type: entityTypesSchema,
  cost: z.number(),
  price: z.number(),
  source_name: z.string(),
  purchase_order_details: purchaseOrderDetailsSchema.optional(),
  transfer_store_id: z.string().optional(),
});

export const createInventoryChangeSchema = z.object({
  store_id: z.string(),
  product_id: z.string(),
  change_quantity: z.number(),
  reason: inventoryChangeReasonSchema,
  merchant_id: z.string(),
  source_id: z.string(),
  cost: z.number(),
  source_type: entityTypesSchema,
  source_name: z.string(),
  product_name: z.string(),
  purchase_order_details: purchaseOrderDetailsSchema.optional(),
  price: z.number(),
  transfer_store_id: z.string().optional(),
});

export const inventoryChangeDBItemSchema = inventoryChangeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  GSI7PK: z.string(),
  GSI7SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getInventoryChangesSchema = z.object({
  by_store_and_product_and_date_range: z.boolean().optional(),
  by_store_and_date_range: z.boolean().optional(),
  by_reason_and_store_and_product_and_date_range: z.boolean().optional(),
  by_reason_and_store: z.boolean().optional(),
  by_reason_across_stores: z.boolean().optional(),
  by_source_and_store: z.boolean().optional(),
  by_source_across_stores: z.boolean().optional(),
  by_reason_and_source_and_store: z.boolean().optional(),
  by_reason_and_source_across_stores: z.boolean().optional(),
  by_product_and_store: z.boolean().optional(),
  by_product_across_stores: z.boolean().optional(),
  by_reason_and_product_and_store: z.boolean().optional(),
  by_reason_and_product_across_stores: z.boolean().optional(),
  by_source_and_product_and_store: z.boolean().optional(),
  by_source_and_product_across_stores: z.boolean().optional(),
  by_source_and_reason_and_product: z.boolean().optional(),
  by_source_and_reason_and_store: z.boolean().optional(),
  by_source_and_reason_across_stores: z.boolean().optional(),
  limit: z.number().optional(),
  cursor: z.string().optional(),
  sort_order: sortOrderSchema.optional(),
  store_id: z.string().optional(),
  product_id: z.string().optional(),
  reason: inventoryChangeReasonSchema.optional(),
  source_type: entityTypesSchema.optional(),
  source_id: z.string().optional(),
  merchant_id: z.string(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
});

export const inventoryChangePageSchema = z.object({
  changes: z.array(inventoryChangeSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const loanHistoryItemSchema = timestampsSchema.extend({
  event: loanHistoryEventSchema,
  created_at: z.string(),
  creator_id: z.string(),
  creator_name: z.string(),
  creator_type: z.string(),
  paid_amount: z.number(),
  total_amount: z.number(),
  unpaid_amount: z.number(),
  due_date: z.string(),
  overdue_amount: z.number(),
});

export const merchantSchema = timestampsSchema.extend({
  id: z.string(),
  business_name: z.string(),
  email: z.string(),
  country: z.string(),
  currency: currencySchema,
  phone_number: z.string(),
  address: z.string(),
  contact_person: z.string(),
  stores: z.array(z.string()),
});

export const merchantPageSchema = z.object({
  merchants: z.array(merchantSchema),
  count: z.number(),
  cursor: z.string().optional(),
});

export const merchantDBItemSchema = merchantSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const emailParamsSchema = z.object({
  bccEmailAddresses: z.array(z.string()).optional(),
  ccEmailAddresses: z.array(z.string()).optional(),
  toEmailAddresses: z.array(z.string()),
  bodyData: z.string(),
  bodyCharset: emailCharsetSchema,
  subjectdata: z.string(),
  subjectCharset: emailCharsetSchema,
  sourceEmail: z.string(),
  replyToAddresses: z.array(z.string()).optional(),
});

export const receiptPaymentDetailsSchema = z.object({
  receipt_id: z.string(),
  payment: receiptPaymentSchema,
  customer_id: z.string(),
});

export const paymentDetailsSchema = z.union([
  loanPaymentDetailsSchema,
  debtPaymentDetailsSchema,
  receiptPaymentDetailsSchema,
  refundReceiptPaymentDetailsSchema,
]);

export const paymentSchema = timestampsSchema
  .extend(commonPaymentAttributesSchema.shape)
  .extend({
    id: z.string(),
    payment_details: paymentDetailsSchema,
    owner_id: z.string(),
    owner_type: entityTypesSchema,
    allocations: z.array(allocationSchema),
  });

export const paymentsPageSchema = paginatedSchema.extend({
  payments: z.array(paymentSchema),
});

export const createReceiptPaymentDtoSchema =
  commonPaymentAttributesSchema.extend(receiptPaymentDetailsSchema.shape);

export const paymentDBItemSchema = paymentSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  GSI7PK: z.string(),
  GSI7SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const getPaymentByPayerDtoSchema = paginationParamsSchema.extend({
  payer_id: z.string(),
  payer_type: entityTypesSchema,
});

export const getPaymentsByEntityAndDateRangeDtoSchema = paginationParamsSchema
  .extend(dateRangeSchema.shape)
  .extend({
    entity_id: z.string(),
    entity_type: entityTypesSchema,
  });

export const productNameOrUnitUpdatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const quantityInUnitPackageUpdatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const storeProductCostUpdatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const changeEventSchema = z.object({
  changes: changedAttributesSchema,
  event: z.union([productChangeEventsSchema, storeProductChangeEventsSchema]),
});

export const storeProductChangesPageSchema = z.object({
  changes: z.array(storeProductChangeSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const promotionSchema = timestampsSchema.extend({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  start_date: z.string(),
  end_date: z.string(),
  promotion_type: promotionTypesSchema,
  promotion_value: z.number(),
  promotion_code: z.string(),
  promotion_status: promotionStatusSchema,
  store_id: z.string(),
  merchant_id: z.string(),
  product_id: z.string(),
  image_url: z.string(),
  is_manual: z.boolean(),
});

export const promotionPageSchema = z.object({
  promotions: z.array(promotionSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const promotionDBItemSchema = promotionSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const purchaseOrderPaymentMerchantSummarySchema =
  purchaseOrderPaymentSummarySchema.extend({
    merchant_id: z.string(),
  });

export const purchaseOrderPaymentStoreSummarySchema =
  purchaseOrderPaymentSummarySchema.extend({
    store_id: z.string(),
  });

export const purchaseOrderPaymentSupplierSummarySchema =
  purchaseOrderPaymentSummarySchema.extend({
    supplier_id: z.string(),
  });

export const purchaseOrderPaymentPurchaseOrderSummarySchema =
  purchaseOrderPaymentSummarySchema.extend({
    purchase_order_id: z.string(),
  });

export const purchaseOrderSchema = timestampsSchema.extend({
  id: z.string(),
  purchase_date: z.string(),
  ordered_by: z.string(),
  note: z.string(),
  supplier_id: z.string(),
  purchase_status: purchaseStatusSchema,
  total_cost: z.number(),
  items: z.array(purchaseOrderItemSchema),
  total_received: z.number(),
  payment_status: purchaseOrderPaymentStatusSchema,
  is_draft: z.boolean(),
  store_id: z.string(),
  merchant_id: z.string(),
  total_ordered: z.number(),
  supplier_name: z.string(),
  total_cancelled: z.number(),
});

export const purchaseOrderPageSchema = z.object({
  purchase_orders: z.array(purchaseOrderSchema),
  cursor: z.union([z.string(), z.undefined()]).nullable(),
  count: z.number(),
});

export const receiptCreatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const confirmedQuantityUpdateSchema = z.object({
  quantity: z.number(),
  updator: employeeSummarySchema,
  item_id: z.string(),
});

export const receiptHistoryItemSchema = z.union([
  saleReceiptHistoryItemSchema,
  debtPaymentHistoryItemSchema,
  refundReceiptHistoryItemSchema,
  receiptHistoryUpdateCustomerItemSchema,
  receiptHistoryCommentItemSchema,
]);

export const taxSchema = timestampsSchema.extend({
  id: z.string(),
  type: taxTypeSchema,
  name: z.string(),
  rate: z.number(),
  merchant_id: z.string(),
});

export const createReceiptHistoryItemSchema = z.object({
  receiptHistory: receiptHistoryItemSchema,
  id: z.string(),
  receipt_number: z.string(),
  created_at: z.string(),
  merchant_id: z.string(),
});

export const receiptHistoryDbItemSchema = receiptHistoryItemSchema.and(
  z.object({
    PK: z.string(),
    SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  }),
);

export const updateLineItemSchema = z.object({
  receipt_number: z.string(),
  confirmed_quantity: z.array(confirmedQuantityUpdateSchema).optional(),
  missing: z.array(missingItemSchema).optional(),
  command: updateReceiptCommandSchema,
  merchant_id: z.string(),
});

export const aggregateStoreSalesReportSchema = storeSalesReportSchema.extend(
  salesReportExtensionsSchema.shape,
);

export const aggregateEmployeeSalesReportSchema =
  employeeSalesReportSchema.extend(salesReportExtensionsSchema.shape);

export const aggregateProductSalesReportSchema =
  productSalesReportSchema.extend(salesReportExtensionsSchema.shape);

export const aggregateSalesReportSchema = z.object({
  store: aggregateStoreSalesReportSchema,
  employees: z.array(aggregateEmployeeSalesReportSchema),
  products: z.array(aggregateProductSalesReportSchema),
});

export const employeePageSchema = z.object({
  employees: z.array(employeeSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const storeCreatedEventDetailDataSchema = z.object({
  owner: employeeSchema,
  store: storeSchema,
});

export const storeCreatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const taxPageSchema = z.object({
  taxes: z.array(taxSchema),
  count: z.number(),
  cursor: z.string().optional(),
});

export const taxDBItemSchema = taxSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const firstMerchantUserCreatedEventDetailDataSchema = z.object({
  user: employeeSchema,
});

export const firstMerchantUserCreatedEventDetailMetadataSchema =
  shopAndSmileEventMetadataSchema;

export const userItemSchema = employeeSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string().optional(),
  GSI3SK: z.string().optional(),
  GSI4PK: z.string().optional(),
  GSI4SK: z.string().optional(),
  PIN: z.string(),
});

export const updateUserSchema = employeeSchema
  .partial()
  .and(updateMerchantUserRequestBodySchema.partial())
  .and(
    z.object({
      id: z.string(),
      auth_type: authTypeSchema,
      is_active: z.boolean().optional(),
      PIN: z.string().optional(),
      stores: z.array(z.string()).optional(),
    }),
  );

export const authenticateUserResponseSchema = z.object({
  encryptedCredentials: z.string(),
  userData: merchantUserAttributesSchema,
  employee: employeeSchema,
  credentials: encryptCredentialsDtoSchema,
});

export const loanSchema = timestampsSchema
  .extend(commonEmployeeAsCreatorAttributesSchema.shape)
  .extend({
    id: z.string(),
    paid_amount: z.number(),
    total_amount: z.number(),
    unpaid_amount: z.number(),
    borrower_id: z.string(),
    borrower_type: entityTypesSchema,
    borrower_loan_id: z.string(),
    due_date: z.string(),
    overdue_amount: z.number(),
    status: loanStatusSchema,
    reason: z.string(),
    associated_payments: z.array(z.string()),
    loan_history: z.array(loanHistoryItemSchema),
    store_id: z.string(),
  });

export const borrowerLoanSchema = timestampsSchema
  .extend(commonEmployeeAsCreatorAttributesSchema.shape)
  .extend({
    id: z.string(),
    borrower_id: z.string(),
    borrower_type: entityTypesSchema,
    total_amount: z.number(),
    total_paid_amount: z.number(),
    total_value_of_completed_loans: z.number(),
    total_number_of_loans: z.number(),
    total_number_of_completed_loans: z.number(),
    total_unpaid_amount: z.number(),
    total_overdue_amount: z.number(),
    oldest_due_date: z.string(),
    status: loanStatusSchema,
    incomplete_loans: z.array(loanSchema),
    reason: z.string(),
  });

export const borrowerLoanPageSchema = z.object({
  borrower_loans: z.array(borrowerLoanSchema),
  cursor: z.string().nullable(),
  count: z.number(),
});

export const loanDBItemSchema = loanSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const loanPageSchema = z.object({
  loans: z.array(loanSchema),
  cursor: z.string().nullable(),
  count: z.number(),
});

export const borrowerLoanSummarySchema = z.object({
  borrower_loan: borrowerLoanSchema,
  incomplete_loans_cursor: z.string().nullable(),
  incomplete_loans_count: z.number(),
});

export const productSchema = timestampsSchema.extend({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  image: z.string(),
  barcode: z.string(),
  quantity_in_unit_package: z.number(),
  global_sku: z.number(),
  weight_kg: z.number(),
  unit: z.string(),
  unit_of_measurement: z.string(),
  category_id: z.string().optional(),
  merchant_id: z.string(),
  fraction: z.number(),
  parent_id: z.string(),
  is_parent: z.boolean(),
  product_fractions: z.array(productFractionSchema),
  taxes: z.union([z.array(z.string()), z.array(taxSchema)]),
  version: z.number(),
});

export const quantityInUnitPackageUpdatedEventDetailDataSchema = z.object({
  parentProduct: productSchema,
  childProducts: z.array(productSchema),
  store: storeSchema,
});

const storeProductCostUpdatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: storeProductCostUpdatedEventDetailDataSchema,
  metadata: storeProductCostUpdatedEventDetailMetadataSchema,
});

export const productPageSchema = z.object({
  products: z.array(productSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const productDBItemSchema = productSchema.extend({
  PK: z.string(),
  SK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
});

export const purchaseOrderPaymentSummariesSchema = z.object({
  merchants: z.array(purchaseOrderPaymentMerchantSummarySchema),
  stores: z.array(purchaseOrderPaymentStoreSummarySchema),
  suppliers: z.array(purchaseOrderPaymentSupplierSummarySchema),
  purchase_orders: z.array(purchaseOrderPaymentPurchaseOrderSummarySchema),
});

export const purchaseOrderPaymentPageSchema = z.object({
  purchase_order_payments: z.array(purchaseOrderPaymentSchema),
  cursor: z.union([z.string(), z.undefined()]).nullable(),
  count: z.number(),
  summary: z
    .union([purchaseOrderPaymentSummariesSchema, z.undefined()])
    .nullable(),
});

export const receiptHistorySchema = z.object({
  receipt_number: z.string(),
  history_item: z.array(receiptHistoryItemSchema),
});

export const receiptTaxSchema = taxSchema.extend({
  money_amount: z.number(),
});

export const storeSalesReportDBItemSnapshotSchema =
  aggregateStoreSalesReportSchema.extend({
    PK: z.string(),
    SK: z.string(),
    GSI1PK: z.string(),
    GSI1SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  });

export const employeeSalesReportDBItemSnapshotSchema =
  aggregateEmployeeSalesReportSchema.extend({
    PK: z.string(),
    SK: z.string(),
    GSI1PK: z.string(),
    GSI1SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  });

export const productSalesReportDBItemSnapshotSchema =
  aggregateProductSalesReportSchema.extend({
    PK: z.string(),
    SK: z.string(),
    GSI1PK: z.string(),
    GSI1SK: z.string(),
    EntityIndexPK: z.string(),
    EntityIndexSK: z.string(),
  });

const storeCreatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: storeCreatedEventDetailDataSchema,
  metadata: storeCreatedEventDetailMetadataSchema,
});

const firstMerchantUserCreatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: firstMerchantUserCreatedEventDetailDataSchema,
  metadata: firstMerchantUserCreatedEventDetailMetadataSchema,
});

export const productNameOrUnitUpdatedEventDetailDataSchema = z.object({
  product: productSchema,
});

export const quantityInUnitPackageUpdatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: quantityInUnitPackageUpdatedEventDetailDataSchema,
  metadata: quantityInUnitPackageUpdatedEventDetailMetadataSchema,
});

export const storeProductCostUpdatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.literal(StoreProductChangeEvents.STORE_PRODUCT_COST_UPDATED),
  detail: storeProductCostUpdatedEventDetailSchema,
});

export const lineItemSchema = z.object({
  id: z.string(),
  item_id: z.string(),
  item_name: z.string(),
  sku: z.string(),
  quantity: z.number(),
  line_quantity: z.number(),
  fraction: z.number(),
  price: z.number(),
  gross_total_money: z.number(),
  total_money: z.number(),
  cost: z.number(),
  cost_total: z.number(),
  line_note: z.string().optional(),
  line_taxes: z.array(receiptTaxSchema),
  total_discount: z.number(),
  line_discounts: z.array(receiptDiscountSchema),
  confirmed_quantity: z.number(),
  confirmed_quantity_traceback: z.array(confirmedQuantityTracebackSchema),
  missing: missingItemSchema,
  missing_quantity_traceback: z.array(missingItemTracebackSchema),
  is_confirmed: z.boolean(),
  is_sold_by_weight: z.boolean(),
});

export const storeCreatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.literal(StoreEvents.STORE_CREATED),
  detail: storeCreatedEventDetailSchema,
});

export const firstMerchantUserCreatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.literal(UserEvents.FIRST_MERCHANT_USER_CREATED),
  detail: firstMerchantUserCreatedEventDetailSchema,
});

const productNameOrUnitUpdatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: productNameOrUnitUpdatedEventDetailDataSchema,
  metadata: productNameOrUnitUpdatedEventDetailMetadataSchema,
});

export const quantityInUnitPackageUpdatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.literal(
    ProductChangeEvents.PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED,
  ),
  detail: quantityInUnitPackageUpdatedEventDetailSchema,
});

export const receiptSchema = timestampsSchema.extend({
  receipt_number: z.string(),
  note: z.string().optional(),
  receipt_type: receiptTypeSchema,
  refund_for: z.string().optional(),
  order: z.string().optional(),
  created_at: z.string(),
  updated_at: z.string(),
  source: z.string(),
  cancelled_at: z.any().optional(),
  total_money: z.number(),
  total_tax: z.number(),
  points_earned: z.number(),
  points_deducted: z.number(),
  points_balance: z.number(),
  customer_id: z.string(),
  total_discount: z.number(),
  employee_id: z.string(),
  store_id: z.string(),
  pos_device_id: z.string(),
  dining_option: z.string().optional(),
  total_discounts: z.array(receiptDiscountSchema),
  total_taxes: z.array(receiptTaxSchema),
  tip: z.number(),
  surcharge: z.number(),
  line_items: z.array(lineItemSchema),
  payments: z.array(receiptPaymentSchema),
  associated_refunds: z.array(z.string()),
  total_receipt_items: z.number(),
  confirmation_type: confirmationTypeSchema,
  receipt_status: receiptStatusSchema,
  comments: z.array(commentSchema),
  client: clientSchema,
  waiter: employeeSummarySchema.optional(),
  is_confirmable: z.boolean(),
  confirmed_by: employeeSummarySchema.optional(),
  customer: customerSchema.optional(),
  customer_name: z.string(),
  employee_name: z.string(),
  pos_device_name: z.string(),
  customer_updated_traceback: z.array(customerUpdateTracebackSchema),
  has_debt: z.boolean(),
  custom_receipt_number: z.string(),
  failed_checks: z.array(receiptFailedChecksSchema),
  merchant_id: z.string(),
  threshold: thresholdSchema,
  initial_payment_plan: paymentPlanSchema.optional(),
});

export const extendedLineItemSchema = lineItemSchema.extend({
  missing: missingItemSchema,
  is_confirmed: z.boolean(),
});

export const receiptDBItemSchema = receiptSchema.extend({
  PK: z.string(),
  SK: z.string(),
  EntityIndexPK: z.string(),
  EntityIndexSK: z.string(),
  GSI1PK: z.string(),
  GSI1SK: z.string(),
  GSI2PK: z.string(),
  GSI2SK: z.string(),
  GSI3PK: z.string(),
  GSI3SK: z.string(),
  GSI4PK: z.string(),
  GSI4SK: z.string(),
  GSI5PK: z.string(),
  GSI5SK: z.string(),
  GSI6PK: z.string(),
  GSI6SK: z.string(),
  GSI7PK: z.string(),
  GSI7SK: z.string(),
});

export const receiptsPageSchema = z.object({
  receipts: z.array(receiptSchema),
  cursor: z.string().optional(),
  count: z.number(),
});

export const productNameOrUnitUpdatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.union([
    z.literal(ProductChangeEvents.PRODUCT_NAME_UPDATED),
    z.literal(ProductChangeEvents.PRODUCT_UNIT_UPDATED),
  ]),
  detail: productNameOrUnitUpdatedEventDetailSchema,
});

export const receiptCreatedEventDetailDataSchema = z.object({
  receipt: receiptSchema,
});

export const receiptCreatedEventDetailSchema = z.object({
  filter: z.record(z.any()).optional(),
  data: receiptCreatedEventDetailDataSchema,
  metadata: receiptCreatedEventDetailMetadataSchema,
});

export const receiptCreatedEventSchema = z.object({
  bus_name: z.string(),
  source: shopAndSmileEventSourceSchema,
  detail_type: z.union([
    z.literal(ReceiptEvents.SaleReceiptCreated),
    z.literal(ReceiptEvents.RefundReceiptCreated),
  ]),
  detail: receiptCreatedEventDetailSchema,
});

export const storeProductFractionSchema: z.ZodSchema<StoreProductFraction> =
  z.lazy(() => storeProductSchema.and(productFractionSchema));

export const storeProductSchema: z.ZodSchema<StoreProduct> = z.lazy(() =>
  productSchema.and(storeProductAttributesSchema),
);

export const storeProductAttributesSchema: z.ZodSchema<StoreProductAttributes> =
  z.lazy(() =>
    timestampsSchema.extend({
      id: z.string(),
      store_sku: z.string(),
      store_id: z.string(),
      unit_of_measurement: z.string(),
      inventory: z.number(),
      is_available_for_sale: z.boolean(),
      is_sold_by_range: z.boolean(),
      is_manually_controlled: z.boolean(),
      should_display_cost_on_pos: z.boolean(),
      is_sold_by_weight: z.boolean(),
      is_sold_online: z.boolean(),
      is_available_in_store: z.boolean(),
      loyalty_programs: z.union([
        z.array(z.string()),
        z.array(loyaltyProgramSchema),
      ]),
      price: z.number(),
      cost: z.number(),
      low_stock_threshold: z.number(),
      store_prefix: z.string(),
      is_parent: z.boolean(),
      merchant_id: z.string(),
      version: z.number(),
      store_product_fractions: z.array(storeProductFractionSchema),
      max_selling_price: z.number(),
      min_selling_price: z.number(),
      profit_margin: z.number().optional(),
      max_profit_margin: z.number().optional(),
      min_profit_margin: z.number().optional(),
    }),
  );

export const storeProductPageSchema: z.ZodSchema<StoreProductPage> = z.lazy(
  () =>
    z.object({
      products: z.array(storeProductSchema),
      cursor: z.string().optional(),
      count: z.number(),
    }),
);

export const storeProductDBItemSchema: z.ZodSchema<StoreProductDBItem> = z.lazy(
  () =>
    storeProductAttributesSchema.and(
      z.object({
        PK: z.string(),
        SK: z.string(),
        GSI1PK: z.string(),
        GSI1SK: z.string(),
        GSI2PK: z.string(),
        GSI2SK: z.string(),
        EntityIndexPK: z.string(),
        EntityIndexSK: z.string(),
      }),
    ),
);

export const storeProductWebSocketMessageSchema: z.ZodSchema<StoreProductWebSocketMessage> =
  z.lazy(() =>
    z.object({
      type: entityTypesSchema,
      message: storeProductSchema,
    }),
  );

import { Employee, Resource, Role } from '@bofrak-backend/shared';
import { apiAdapter } from '@bofrak-backend/shared-ui';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { flatten } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { BsBoxSeam, BsPersonVcard } from 'react-icons/bs';
import { GiShoppingCart } from 'react-icons/gi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoSettingsOutline } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';
import { PiCashRegister } from 'react-icons/pi';
import { useQueries, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuthenticator } from '../hooks/use-authenticator';
import {
  encryptedCredentialsAtom,
  isSignedInAtom,
  selectedStoreAtom,
  userAtom,
} from '../recoil/atoms';
import { envVars, paths } from '../utils/constants';
import StoreDisplay from './store';

interface AppName {
  name: string;
  url: string;
  icon: IconType;
  page?: string;
}

const appNames: AppName[] = [
  { name: 'Reports', url: 'reports', icon: HiOutlineDocumentReport },
  { name: 'Products', url: 'products', icon: BsBoxSeam },
  { name: 'Point of Sale', url: 'pos', icon: PiCashRegister },
  { name: 'Inventory', url: 'inventory', icon: AiOutlineProduct },
  { name: 'Purchases', url: 'purchase-orders', icon: GiShoppingCart },
  { name: 'Clients', url: 'clients', icon: BsPersonVcard },
  { name: 'Settings', url: 'settings', icon: IoSettingsOutline },
];

export const DashboardPage = () => {
  const domain = `shopnsmile.org`;
  const toast = useToast();
  const navigate = useNavigate();
  const stage = envVars.STAGE;

  const encryptedCredentials = useRecoilValue(encryptedCredentialsAtom);
  const user = useRecoilValue(userAtom);
  const isSignedIn = useRecoilValue(isSignedInAtom);
  const [selectedStore, setSelectedStore] = useRecoilState(selectedStoreAtom);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const { signOut, isLoading: isSignOutLoading } = useAuthenticator();

  const [port, setPort] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleIds, setRoleIds] = useState<string[]>([]);
  const [allowedApps, setAllowedApps] = useState<AppName[]>([]);
  // Flag to ensure roles are only set once after all queries finish.
  const [hasFetchedRoles, setHasFetchedRoles] = useState(false);
  // Counter to track how many times we've fetched the user
  const [fetchCount, setFetchCount] = useState(0);

  useQuery(
    ['employee', user?.username],
    async () => {
      if (!user?.username) return;
      return await apiAdapter.getUser(user.username);
    },
    {
      enabled: !!user?.username,
      // Only refetch if we haven't reached 15 fetches
      refetchInterval: fetchCount < 15 ? 3000 : false,
      onSuccess: (data) => {
        if (data) {
          setEmployee(data);
          setFetchCount((prev) => prev + 1);
        }
      },
    },
  );

  // Identify the roles for the currently selected store from user’s store list
  useEffect(() => {
    let storeRoles: string[] =
      user?.stores.find(
        (store) =>
          store.store_id.toLowerCase() === selectedStore?.id.toLowerCase(),
      )?.roles ?? [];

    if (employee) {
      storeRoles =
        employee.auth_user?.stores.find(
          (store) =>
            store.store_id.toLowerCase() === selectedStore?.id.toLowerCase(),
        )?.roles ?? [];
    }

    setRoleIds(storeRoles);

    // Clear out apps when store changes or no roles
    if (storeRoles.length === 0) {
      setRoles([]);
      setAllowedApps([]);
      setHasFetchedRoles(false);
    }
  }, [selectedStore, user, employee]);

  // Fetch all roles in parallel, keyed by the store ID and each roleId
  const roleQueries = useQueries(
    roleIds.map((roleId) => ({
      queryKey: ['role', roleId, selectedStore?.id],
      queryFn: () => apiAdapter.getRole(roleId),
      enabled: !!roleIds.length && !!selectedStore?.id,
    })),
  );

  // Once all role fetches are done, update the roles only once.
  useEffect(() => {
    const allFinished =
      roleQueries.length > 0 &&
      roleQueries.every((q) => q.status === 'success' || q.status === 'error');

    if (allFinished && !hasFetchedRoles) {
      const fetchedRoles = roleQueries
        .filter((q) => q.isSuccess)
        .map((q) => q.data as Role);
      setRoles(fetchedRoles);
      setHasFetchedRoles(true);
    }
  }, [roleQueries, hasFetchedRoles]);

  // Derive allowed apps from roles
  useEffect(() => {
    if (!roles.length) {
      setAllowedApps([]);
      return;
    }
    const resources: Resource[] = flatten(
      roles.map((role) => role.resources.map((res) => JSON.parse(res))),
    );

    // Example: resource.path = "reports.stage.shopnsmile.org", etc.
    const appNameUrls = appNames.map((app) => `${app.url}.${stage}.${domain}`);

    // Filter resources that match one of our known subdomain paths
    const allowedResources = resources.filter((res) =>
      appNameUrls.includes(res.path),
    );

    // Filter the appNames that correspond to those resources
    const allowedAppNames = appNames.filter((app) =>
      allowedResources.find((res) => res.path.includes(app.url)),
    );

    setAllowedApps(allowedAppNames);
  }, [roles, stage, domain]);

  // Handle store change
  const handleClickBack = () => {
    setSelectedStore(null);
    navigate(paths.home);
  };

  // Render shortcuts to allowed apps
  const renderAllowedApps = () => {
    return (
      <SimpleGrid columns={2} spacing={5}>
        {allowedApps.map((app, i) => (
          <Box
            key={app.url + i}
            cursor="pointer"
            textAlign="center"
            onClick={() => {
              const localDomain = app.page ? domain + app.page : domain;

              if (!selectedStore?.id) {
                toast({
                  title: 'Select a store first',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              if (encryptedCredentials && user && isSignedIn) {
                window.location.href = `https://${app.url}.${stage}.${localDomain}?token=${encodeURIComponent(
                  encryptedCredentials,
                )}&store_id=${selectedStore?.id}`;
              } else {
                toast({
                  title: 'Sign In First',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                navigate(paths.home);
              }
            }}>
            <Box
              p={4}
              textTransform="capitalize"
              bg="gray.100"
              boxShadow="xl"
              borderRadius={8}>
              <Icon as={app.icon} fontSize="48px" />
              <Text fontSize="large" fontWeight="bold">
                {app.name}
              </Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  // Overall return
  return (
    <Flex
      minH="100vh"
      align="flex-start"
      width="full"
      justify="center"
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} width="100%" py={2} px={6}>
        {/* Header / Greeting */}
        <Flex
          justify="center"
          direction="column"
          align="center"
          width="100%"
          gap={4}>
          <Heading textAlign="center">Hello {user?.given_name}</Heading>
          {/* Back button if store is selected */}
          {selectedStore && (
            <Flex justify="start" pl={5} align="center" width="100%" gap={4}>
              <Button size="sm" onClick={handleClickBack}>
                Back
              </Button>
            </Flex>
          )}

          {/* Development port input (only when not prod and store is selected) */}
          {!stage.includes('prod') && selectedStore && (
            <VStack borderRadius={'15px'} bg="white" p={3} shadow={'lg'}>
              <Text fontSize="sm" color="gray.500">
                Only works in development mode
              </Text>
              <InputGroup size="md">
                <Input
                  onChange={(e) => setPort(e.target.value)}
                  pr="4.5rem"
                  type="text"
                  placeholder="Dev Mode Port (e.g 5174)"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => {
                      if (!encryptedCredentials) {
                        toast({
                          title: 'Sign In First',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }
                      if (!selectedStore?.id) {
                        toast({
                          title: 'Select a store first',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                        });
                        return;
                      }
                      window.location.href = `http://localhost:${port}?token=${encodeURIComponent(
                        encryptedCredentials,
                      )}&store_id=${selectedStore.id}`;
                    }}>
                    Go
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          )}

          {/* Store Name */}
          {selectedStore && (
            <Heading as="h1" size="lg" textAlign="center">
              {selectedStore.name}
            </Heading>
          )}
        </Flex>

        <Box
          width="full"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
          minHeight="70vh">
          {/* 1) No store selected → show store list */}
          {!selectedStore && <StoreDisplay />}

          {/* 3) If store selected, roles are fetched & user has some apps → show grid */}
          {selectedStore?.id && allowedApps.length > 0 && renderAllowedApps()}

          {/* 4) If store selected, roles are fetched & no allowed apps → show "no access" card */}
          {selectedStore?.id && allowedApps.length === 0 && (
            <Card>
              <CardHeader>
                <Text textAlign={'center'}>
                  You have no access to any apps in this store
                </Text>
              </CardHeader>

              <CardBody>
                {employee?.is_owner ? (
                  <Text textAlign="center">
                    Please wait a few minutes for the system to update your
                    roles. If the issue persists, contact support.
                  </Text>
                ) : (
                  <Text textAlign="center">
                    Please contact the store owner to grant you access to apps.
                  </Text>
                )}
              </CardBody>
            </Card>
          )}

          {/* Sign Out */}
          <Flex justify="center" align="center" width="100%" gap={4}>
            <Button
              my={5}
              width="50%"
              bg="red.500"
              isLoading={isSignOutLoading}
              onClick={async () => {
                await signOut();
                setSelectedStore(null);
                navigate(paths.home);
              }}>
              Sign Out
            </Button>
          </Flex>
        </Box>
      </Stack>
    </Flex>
  );
};

import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';

import { isValidPhoneNumber, toValidPhoneNumber } from '@bofrak-backend/shared';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { shopAndSmileAdapter } from '../api/api';
import image from '../assets/sns.svg';
import { useAuthenticator } from '../hooks/use-authenticator';
import { userAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';

export const Register = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { signIn, user } = useAuthenticator();
  const [fullName, setFullName] = useState('');
  const [business, setBusiness] = useState('');
  const [city, setCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [show, setShow] = useState(false);
  const setUser = useSetRecoilState(userAtom);
  const [message, setMessage] = useState({
    message: '',
    type: 'info',
  });
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegister = async () => {
    if (!fullName || !business || !city || !phoneNumber || !email || !pin) {
      toast({
        title: 'Invalid Inputs',
        description: 'All fields are required',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: 'Invalid Email',
        description: 'Please enter a valid email address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!isValidPhoneNumber(toValidPhoneNumber(phoneNumber))) {
      toast({
        title: 'Invalid Phone Number',
        description: 'Please enter a valid phone number',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      setMessage({
        message: 'Creating merchant, please wait...',
        type: 'info',
      });

      const merchant = await shopAndSmileAdapter.createMerchant({
        address: city,
        business_name: business,
        email,
        contact_person: fullName,
        country: 'Mozambique',
        currency: {
          code: 'MZN',
          decimal_places: 2,
        },
        phone_number: phoneNumber,
        stores: [],
      });

      setMessage({
        message: 'Creating user, please wait...',
        type: 'info',
      });

      const user = await shopAndSmileAdapter.createUser({
        email,
        merchant_id: merchant.id,
        name: fullName,
        phone_number: phoneNumber,
        PIN: pin,
        roles: [],
        stores: [],
        username: phoneNumber,
      });

      console.log('🚀 ~ file: Register.tsx:109 ~ handleRegister ~ user:', user);

      toast({
        title: 'Registration Successful',
        description: 'Your business has been registered',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      await signIn(phoneNumber, pin);

      // Reset form
      setFullName('');
      setBusiness('');
      setCity('');
      setPhoneNumber('');
      setEmail('');
      setPin('');
    } catch (error) {
      setMessage({
        message: 'Error creating merchant, please try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);

      if (user) {
        setUser(user);
      }

      navigate(paths.newStore);
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'flex-start'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Image objectFit="cover" src={image} alt="shop and smile logo" />
          {!message.message ? (
            <Text fontSize={'md'} color={'gray.600'}>
              Register your business✌️
            </Text>
          ) : (
            <Text
              fontSize={'md'}
              color={message.type === 'error' ? 'red.600' : 'blue'}>
              {message.message}
            </Text>
          )}
        </Stack>

        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                value={fullName}
                placeholder="Full Name: Ismael Mondlane"
                onChange={(e) => setFullName(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                value={business}
                placeholder="Business: Inhambane LDA"
                onChange={(e) => setBusiness(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                value={city}
                placeholder="City: Matola"
                onChange={(e) => setCity(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                value={phoneNumber}
                placeholder="+258XXXXXXXXX"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={'text'}
                value={email}
                placeholder="email@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? 'text' : 'password'}
                value={pin}
                placeholder="PIN: XXXX"
                onChange={(e) => setPin(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Stack spacing={5}>
              <Button
                bg={'blue.400'}
                isLoading={isLoading}
                onClick={handleRegister}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Register
              </Button>
              <Button
                bg={'red.400'}
                isLoading={isLoading}
                onClick={() => navigate(paths.home)}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Back
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/sns.svg';
import { UpdateMerchantUserRequestBody } from '@bofrak-backend/shared';
import { shopAndSmileAdapter } from '../api/api';
import { Loader } from '@bofrak-backend/shared-ui';

export const EmailVerified = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const [verificationError, setVerificationError] = useState(false);

  useEffect(() => {
    // Extract the user_id from the URL (assuming it's part of the query string or path)
    const searchParams = new URLSearchParams(window.location.search); // for query string like "?user_id=123"
    const userIdFromPath = window.location.pathname.split('/').pop(); // for path like "/verify/123"

    const userIdFromSearch = searchParams.get('user_id'); // if in query string
    setUserId(userIdFromSearch || userIdFromPath || null); // prefer query string, fallback to path

    console.log(userIdFromSearch, userIdFromPath);
  }, []);

  // Define the mutation using React Query's useMutation
  const mutation = useMutation({
    mutationFn: (requestBody: UpdateMerchantUserRequestBody) =>
      shopAndSmileAdapter.updateMerchantUserInCognito(requestBody),
    onSuccess: () => {
      setIsVerified(true); // Set the verification state to true on success
    },
    onError: () => {
      setVerificationError(true); // Set the error state on failure
    },
  });

  useEffect(() => {
    if (userId && !isVerified) {
      // Build the request body for the updateMerchantUserAttributes function
      const requestBody: UpdateMerchantUserRequestBody = {
        user_id: userId,
        is_email_verified: true, // Assuming you are verifying the email
      };

      // Trigger the mutation to update the email verification
      mutation.mutate(requestBody);
    } else {
      setVerificationError(true); // If no user_id is present, set error
    }
  }, [userId]);

  return (
    <Flex
      height="100vh"
      align="center"
      justify="flex-start"
      direction="column"
      textAlign="center">
      <Image
        mt="100px"
        objectFit="cover"
        src={image}
        alt="shop and smile logo"
      />

      {isVerified ? (
        <>
          <Heading textAlign="center" mb={4}>
            Your Email was verified!
          </Heading>
          <Button as={Link} to="/" colorScheme="green">
            Continue
          </Button>
        </>
      ) : verificationError ? (
        <>
          <Heading color="red" textAlign="center" mb={4}>
            Email Not Verified
          </Heading>
          <Text color="red">
            Please contact customer service for further assistance.
          </Text>
        </>
      ) : (
        <Loader /> // You could also show a loading state
      )}
    </Flex>
  );
};

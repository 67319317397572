// import { decodeTime, isValid } from 'ulidx';

// export function ulidToDate(ulid: string): string {
//   // if (!isValid(ulid)) {
//   //   throw new Error('Invalid ULID length');
//   // }
//   // const timestamp = decodeTime(ulid);

//   // ULID timestamps are in milliseconds
//   const date = new Date(timestamp);
//   return date.toISOString();
// }

import { AxiosStatic } from 'axios';
import CryptoJS from 'crypto-js';
import axiosRetry from 'axios-retry';

export function convertToSentence(s: string): string {
  if (!s) return '';

  let words: string[];

  // Check for snake_case or SCREAMING_SNAKE_CASE
  if (s.includes('_')) {
    words = s.split('_');
  }
  // Check for kebab-case
  else if (s.includes('-')) {
    words = s.split('-');
  }
  // Handle camelCase or PascalCase by inserting spaces before uppercase letters following lowercase letters.
  else {
    s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
    words = s.split(' ');
  }

  // Capitalize the first letter of each word (rest in lowercase)
  const sentenceWords = words
    .filter((word) => word.length > 0)
    .map((word) => {
      word = word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

  return sentenceWords.join(' ');
}

export function isProductForSale({
  fraction,
  inventory,
  is_available_for_sale,
  is_available_in_store,
  is_sold_online,
}: {
  is_available_for_sale: boolean;
  inventory: number;
  fraction: number;
  is_available_in_store: boolean;
  is_sold_online: boolean;
}): { is_available_for_sale: boolean; reason: string } {
  const is_available_for_sale1 =
    is_available_for_sale &&
    inventory >= fraction &&
    is_available_in_store &&
    is_sold_online;
  let reason = '';

  if (!is_available_for_sale) {
    reason = 'Not enough inventory or product is manually disabled';
  } else if (!is_available_in_store) {
    reason = 'Product cannot be sold in this store';
  } else if (!is_sold_online) {
    reason = 'Product is not sold online';
  } else if (inventory < fraction) {
    reason = 'Product inventory is less than fraction';
  }

  return { is_available_for_sale: is_available_for_sale1, reason };
}

export function sanitizePrimitives(
  obj: Record<string, any>,
): Record<string, any> {
  // Recursively sanitize all primitive attributes in the object
  const traverse = (data: any): any => {
    if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        const value = data[key];

        // Skip sanitization for existing booleans
        if (typeof value === 'boolean') {
          return; // Do nothing for booleans
        }

        // Check if the value is a string that can be converted to a primitive
        if (typeof value === 'string') {
          const lowerValue = value.toLowerCase();

          // Convert boolean-like strings
          if (lowerValue === 'true') {
            data[key] = true;
          } else if (lowerValue === 'false') {
            data[key] = false;
          }
          // Check if the string can be converted to a number (int or float)
          else if (!isNaN(value as any) && value.trim() !== '') {
            const numberValue = parseFloat(value);
            data[key] =
              numberValue % 1 === 0 ? parseInt(value, 10) : numberValue;
          }
        }

        // Recursively sanitize nested objects and arrays
        if (typeof value === 'object' && value !== null) {
          data[key] = traverse(value);
        }
      });
      return data; // Explicitly return the sanitized object
    } else if (Array.isArray(data)) {
      return data.map(traverse); // Ensure arrays are retained
    } else if (typeof data === 'string') {
      // Sanitize primitive values that are standalone (not inside an object/array)
      const lowerValue = data.toLowerCase();

      if (lowerValue === 'true') {
        return true;
      } else if (lowerValue === 'false') {
        return false;
      } else if (!isNaN(data as any) && data.trim() !== '') {
        const numberValue = parseFloat(data);
        return numberValue % 1 === 0 ? parseInt(data, 10) : numberValue;
      }
    }

    return data; // Return unmodified data if no sanitization is performed
  };

  return traverse({ ...obj });
}

export function calculateWeightedCost({
  currentQuantity,
  currentCost,
  newQuantity,
  newCost,
}: {
  currentQuantity: number;
  currentCost: number;
  newQuantity: number;
  newCost: number;
}): number {
  // Calculate the total cost of current and new stock
  const totalCurrentCost = currentQuantity * currentCost;
  const totalNewCost = newQuantity * newCost;
  const totalCost = totalCurrentCost + totalNewCost;

  // Calculate the total quantity
  const totalQuantity = currentQuantity + newQuantity;

  // Calculate and return the new weighted cost
  return Math.round(totalCost / totalQuantity);
}

export function getReceiptId({
  posPrefix,
  receiptNumber,
}: {
  receiptNumber: number;
  posPrefix: string;
}): string {
  return `POS-${posPrefix}-R-${receiptNumber}`;
}

export function getReceiptNumber(receiptId: string): number {
  return parseInt(receiptId.split('-').pop()!);
}

// Helper function to encrypt credentials
export const encryptString = (toEncrypt: string, key: string): string => {
  return CryptoJS.AES.encrypt(toEncrypt, key).toString();
};

// Helper function to decrypt credentials
export const decryptString = (encrypted: string, key: string): string => {
  try {
    const bytes = CryptoJS.AES.decrypt(encrypted, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (error) {
    console.error(error);
  }

  return 'Failed to decrypt credentials. Data might be corrupted or invalid.';
};

export function constructPassword({
  phone_number,
  pin,
}: {
  phone_number: string;
  pin: string;
}) {
  return `ShopNSmile${phone_number.replace('+', '')}${pin}`;
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  // Define a regular expression pattern for E.164 format
  const e164Pattern = /^\+[1-9]\d{1,14}$/;

  console.log(`Phone number: '${phoneNumber}'`);

  // Test the phone number against the pattern
  return e164Pattern.test(phoneNumber.trim());
}

export function toValidPhoneNumber(phoneNumber: string): string {
  // Remove all non-digit characters from the phone number
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Ensure the phone number has a valid country code (starts with 1-9)
  // and does not exceed the maximum length of 15 digits
  if (
    digitsOnly.length > 0 &&
    digitsOnly.length <= 15 &&
    /^[1-9]/.test(digitsOnly)
  ) {
    console.log(`+${digitsOnly}`);
    return `+${digitsOnly}`;
  }

  // Return an empty string or handle it as an invalid phone number
  return '';
}

export function isValidPin(pin: string): boolean {
  // Define a regular expression pattern for a 4-digit pin
  const _4pinPattern = /^\d{4}$/;
  const _5pinPattern = /^\d{5}$/;
  const _6pinPattern = /^\d{6}$/;

  // Test the pin against the pattern
  return (
    _4pinPattern.test(pin) || _5pinPattern.test(pin) || _6pinPattern.test(pin)
  );
}

export const reverseString = (str: string): string => {
  return str.split('').reverse().join('');
};

export const getLastNCharacters = (str: string, n: number): string => {
  return str.slice(-n);
};

export const cleanObject = (obj: any) => {
  const newObj: { [key: string]: any } = Array.isArray(obj) ? [] : {};

  for (const propName in obj) {
    const value = obj[propName];
    if (value === null || value === undefined || value === '') {
      continue;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      const cleanedObj = cleanObject(value);
      if (Object.keys(cleanedObj).length > 0) {
        newObj[propName] = cleanedObj;
      }
    } else {
      newObj[propName] = value;
    }
  }

  return newObj;
};

export const isValidDate = (date: string): boolean => {
  if (typeof date !== 'string') {
    return false;
  }

  if (date.trim() === '') {
    return false;
  }

  if (date.length < 10) {
    return false;
  }

  const d = new Date(date);
  return !isNaN(d.valueOf());
};

export const isDate = (date: string): boolean => {
  return !isNaN(Date.parse(date));
};

export const flattenFirstLevel = (obj: any) => {
  const flattenedObj: { [key: string]: any } = {};

  for (const propName in obj) {
    if (
      typeof obj[propName] === 'object' &&
      !Array.isArray(obj[propName]) &&
      obj[propName]
    ) {
      for (const key in obj[propName]) {
        flattenedObj[key] = obj[propName][key];
      }
    } else {
      flattenedObj[propName] = obj[propName];
    }
  }

  return flattenedObj;
};

export function getISOWeekNumber(date: Date): number {
  // Copy date so don't modify original
  const dateCopy = new Date(date.getTime());
  // Set to nearest Thursday: current date + 4 - current day number, make Sunday's day number 7
  dateCopy.setDate(dateCopy.getDate() + 4 - (dateCopy.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(dateCopy.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(
    ((dateCopy.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
  );
  return weekNo;
}

export function isJsonObject(obj: any): boolean {
  try {
    JSON.parse(JSON.stringify(obj));
    return true;
  } catch (error) {
    return false;
  }
}

export function replaceBooleanStrings(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(replaceBooleanStrings);
  } else if (obj !== null && typeof obj === 'object') {
    const newObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[key] = replaceBooleanStrings(value);
    }
    return newObj;
  } else if (typeof obj === 'string') {
    if (obj.toLowerCase() === 'true') return true;
    if (obj.toLowerCase() === 'false') return false;
  }
  return obj;
}

export function processBooleanStringsInJSON(obj: any): any {
  if (!isJsonObject(obj)) {
    return obj;
  }
  return replaceBooleanStrings(obj);
}

/**
 * Returns a new query string with only the key value pairs that have a value
 * @param queryStringKeyVPairs - key value pairs of param name to param value Record<string, string | null | undefined | number | boolean | string[] | number[] | boolean[]>
 * @returns
 * @example objectToQueryString({ cursor: '123', limit: '10', show_deleted: 'false', suppliers_ids: ['1', '2', '3'] })
 * // which returns 'cursor=123&limit=10&show_deleted=false&suppliers_ids=1,2,3'
 */
export function objectToQueryString(
  queryStringKeyVPairs: Record<
    string,
    | string
    | null
    | undefined
    | number
    | boolean
    | string[]
    | number[]
    | boolean[]
  >,
): string {
  const entries = Object.entries(queryStringKeyVPairs);

  let newQueryString = '?';
  let firstPairAdded = false;

  for (const [key, value] of entries) {
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      value === 'undefined' ||
      value === 'null'
    ) {
      continue;
    }

    let valueString: string;
    if (Array.isArray(value)) {
      // Convert each element to string and join with commas, encode each element individually
      valueString = value.map((v) => encodeURIComponent(String(v))).join(',');
    } else {
      // Convert value to string and encode it
      valueString = encodeURIComponent(String(value));
    }

    if (firstPairAdded) {
      newQueryString += '&';
    }

    newQueryString += `${key}=${valueString}`;
    firstPairAdded = true;
  }

  return newQueryString;
}

/**
 * Formats a given amount as currency.
 * @param amount - The amount to format as currency.
 * @returns The formatted currency string.
 */
export const formatCurrency = (amount?: number, whole: boolean = true) => {
  return new Intl.NumberFormat('pt', {
    style: 'currency',
    maximumFractionDigits: whole == true ? 0 : 2,
    currency: 'MZM',
  })
    .format(amount ?? 0)
    .replace('MZM', 'MT');
};

export function isStringifiedJSON(str: string): boolean {
  try {
    // Attempt to parse the string using JSON.parse
    JSON.parse(str);
    return true;
  } catch (e) {
    // If an error is thrown, it means that the string is not a valid JSON
    return false;
  }
}

export function axiosRetryConfig(axios: AxiosStatic, retries?: number) {
  // Custom exponential delay function with an initial delay of 1 second (1000 milliseconds)
  const customExponentialDelay = (retryCount: number) => {
    const baseDelay = 1000; // 1 second in milliseconds
    return baseDelay * Math.pow(2, retryCount - 1);
  };

  // Configure axios-retry with the custom delay, retry condition, and logging
  axiosRetry(axios, {
    retries: retries ?? 2,
    retryDelay: customExponentialDelay,
    retryCondition: (error) => {
      const shouldRetry =
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        (error.response && error.response.status >= 500);
      return shouldRetry === undefined ? false : shouldRetry;
    },
    onRetry: (retryCount, error, requestConfig) => {
      console.log(`Retry attempt ${retryCount}`);
      console.log(`URL: ${requestConfig.url}`);
      if (error.response) {
        console.log(`Status Code: ${error.response.status}`);
        console.log(`Response Data: ${JSON.stringify(error.response.data)}`);
      } else {
        console.log(`Error Message: ${error.message}`);
      }
    },
  });
}

export const constructStoreSKU = ({
  global_sku,
  store_prefix,
}: {
  global_sku: number;
  store_prefix: string;
}): string => {
  if (!global_sku) {
    throw new Error('Global SKU is required');
  }

  if (global_sku < 0) {
    throw new Error('Global SKU must be a positive number');
  }

  if (!store_prefix) {
    throw new Error('Store prefix is required');
  }

  return `${global_sku}${store_prefix}`.toUpperCase();
};

/**
 * Simplifies a fraction given a numerator and denominator.
 *
 * This function computes the greatest common divisor (GCD) of the numerator and denominator
 * using the Euclidean algorithm, divides both by the GCD, and returns the simplified fraction
 * as a string in the format "Nsimplified/Dsimplified".
 *
 * @param n - The numerator of the fraction.
 * @param d - The denominator of the fraction. Must not be zero.
 * @returns The simplified fraction as a string.
 * @throws Will throw an error if the denominator is zero.
 */
export function simplifyFraction(n: number, d: number): string {
  if (d === 0) {
    throw new Error('Denominator cannot be zero');
  }

  /**
   * Computes the greatest common divisor (GCD) of two numbers using the Euclidean algorithm.
   *
   * @param a - The first number.
   * @param b - The second number.
   * @returns The greatest common divisor of `a` and `b`.
   */
  function gcd(a: number, b: number): number {
    return b === 0 ? Math.abs(a) : gcd(b, a % b);
  }

  const commonDivisor = gcd(n, d);
  let simplifiedN = n / commonDivisor;
  let simplifiedD = d / commonDivisor;

  // Ensure the denominator is positive.
  if (simplifiedD < 0) {
    simplifiedN = -simplifiedN;
    simplifiedD = -simplifiedD;
  }

  return `${simplifiedN}/${simplifiedD}`;
}

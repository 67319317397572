import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import { AuthType, CreateStore, UpdateUser } from '@bofrak-backend/shared';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { shopAndSmileAdapter } from '../../api/api';
import ShopAndSmileLogo from '../../components/resuable/Logo';
import { employeeAtom } from '../../recoil/atoms';
import './styles.css';

interface FormValues {
  storename: string;
  nameContactPerson: string;
  phoneNumberContactPerson: string;
  address: string;
  city: string;
  phone: string;
  taxNumber: string;
  description: string;
  roles: string[];
}

interface NewStoreProps {
  onClose: () => void;
}

const NewStore = ({ onClose }: NewStoreProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();
  const queryClient = useQueryClient();
  const [user, setUser] = useRecoilState(employeeAtom);

  const toast = useToast();

  const { isLoading, mutateAsync } = useMutation(
    'createStore',
    (data: CreateStore) => shopAndSmileAdapter.createStore(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          queryClient.invalidateQueries('stores');
          toast({
            title: 'Store created.',
            description: 'Store has been created successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          reset(); // Reset the form after submission
        }
      },
      onError: (error) => {
        toast({
          title: 'An error occurred.',
          description: 'Unable to create store',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        console.log(error);
      },
    },
  );

  const { mutateAsync: updateUserMutation } = useMutation(
    'createStore',
    (data: UpdateUser) => shopAndSmileAdapter.updateUser(data),
    {
      onSuccess: (data) => {
        setUser(data);
      },
      onError: (error) => {
        toast({
          title: 'An error occurred.',
          description: 'Unable to update user',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        console.log(error);
      },
    },
  );

  const onSubmit = async (data: FormValues) => {
    try {
      if (!user?.merchant_id) {
        toast({
          title: 'No merchant found on user',
          description: 'Unable to create store',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      const store = await mutateAsync({
        name: data.storename,
        merchant_id: user.merchant_id,
        contact_person: data.nameContactPerson,
        contact_person_phone_number: data.phoneNumberContactPerson,
        city: data.city,
        phone_number: data.phone,
        address: data.address,
        tax_number: data.taxNumber,
        description: data.description,
      });

      toast({
        title: 'Adding user to store',
        status: 'info',
        duration: 4000,
        isClosable: true,
      });

      const updatedUser = await updateUserMutation({
        id: user.id,
        add_to_stores: [
          {
            store_id: store.id,
            store_name: store.name,
            merchant_id: store.merchant_id,
            roles: data.roles || [],
          },
        ],
        auth_type: AuthType.Merchant,
      });

      if (updatedUser) {
        setUser(updatedUser);
      }

      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      width={'100%'}
      padding={'20px'}>
      <Box w="full">
        <ShopAndSmileLogo width={200} />
        <Heading size="md" textAlign="center" mb={4}>
          Create Store
        </Heading>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: '100%',
          }}>
          <Stack spacing={4} width={'100%'}>
            <FormControl id="storename" isInvalid={!!errors.storename}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Store Name"
                {...register('storename', {
                  required: 'Store Name is required',
                })}
              />
              {errors.storename && (
                <Text color="red.500" fontSize="sm">
                  {errors.storename.message}
                </Text>
              )}
            </FormControl>

            <FormControl
              id="nameContactPerson"
              isInvalid={!!errors.nameContactPerson}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Name of Contact Person"
                {...register('nameContactPerson', {
                  // required: 'Name of Contact Person is required',
                })}
              />
              {errors.nameContactPerson && (
                <Text color="red.500" fontSize="sm">
                  {errors.nameContactPerson.message}
                </Text>
              )}
            </FormControl>

            <FormControl
              id="phoneNumberContactPerson"
              isInvalid={!!errors.phoneNumberContactPerson}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Phone Number of Contact Person"
                {...register(
                  'phoneNumberContactPerson',
                  // required in format eg.. +263777777777
                  {
                    // required: 'Phone Number of Contact Person is required',
                    pattern: {
                      value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                      message:
                        'Invalid phone number, format must be +XXXXXXXXX',
                    },
                  },
                )}
              />
              {errors.phoneNumberContactPerson && (
                <Text color="red.500" fontSize="sm">
                  {errors.phoneNumberContactPerson.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="address" isInvalid={!!errors.address}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Address"
                // {...register('address', { required: 'Address is required' })}
                {...register('address', {})}
              />
              {errors.address && (
                <Text color="red.500" fontSize="sm">
                  {errors.address.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="city" isInvalid={!!errors.city}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="City"
                {...register('city', { required: 'City is required' })}
              />
              {errors.city && (
                <Text color="red.500" fontSize="sm">
                  {errors.city.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="phone" isInvalid={!!errors.phone}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Phone Number"
                {...register('phone', {
                  // required: 'Phone Number of Contact Person is required',
                  pattern: {
                    value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                    message: 'Invalid phone number, format must be +XXXXXXXXX',
                  },
                })}
              />
              {errors.phone && (
                <Text color="red.500" fontSize="sm">
                  {errors.phone.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="taxNumber" isInvalid={!!errors.taxNumber}>
              <Input
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                type="text"
                placeholder="Tax Number"
                {...register('taxNumber', {
                  // required: 'Tax Number is required',
                })}
              />
              {errors.taxNumber && (
                <Text color="red.500" fontSize="sm">
                  {errors.taxNumber.message}
                </Text>
              )}
            </FormControl>

            <FormControl id="description" isInvalid={!!errors.description}>
              <Textarea
                _focus={{
                  boxShadow: 'none',
                  border: '2',
                  borderColor: 'blue.200',
                }}
                placeholder="Description"
                {...register('description', {
                  // required: 'Description is required',
                })}
              />
              {errors.description && (
                <Text color="red.500" fontSize="sm">
                  {errors.description.message}
                </Text>
              )}
            </FormControl>

            <Button
              isLoading={isLoading}
              colorScheme="blue"
              w="full"
              type="submit">
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default NewStore;

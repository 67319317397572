import { Store } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { shopAndSmileAdapter } from '../../api/api';
import ModalComponent from '../../components/resuable/Modal';
import { employeeAtom, selectedStoreAtom } from '../../recoil/atoms';
import NewStore from './NewStore';

interface StoreCardProps {
  store: Store;
  setSelectedStore: (store: Store) => void;
}

const StoreCard = ({ store, setSelectedStore }: StoreCardProps) => {
  return (
    <Box
      cursor={'pointer'}
      width={'100px'}
      height={'100px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={'gray.100'}
      p={5}
      borderRadius={5}
      onClick={() => setSelectedStore(store)}>
      <Text color={'gray.700'} textAlign={'center'} fontSize={'sm'}>
        {store?.name}
      </Text>
    </Box>
  );
};

const StoreDisplay = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const employee = useRecoilValue(employeeAtom);
  const setSelectedStore = useSetRecoilState(selectedStoreAtom);
  const [stores, setStores] = useState<Store[]>([]);
  const toast = useToast();

  const handleOpenModal = () => {
    onOpen();
  };

  const storeIds = employee?.stores.map((store) => store);

  const { status } = useQuery(
    ['stores', storeIds],
    async () => {
      if (!employee?.merchant_id) {
        toast({
          title: 'Merchant ID not found',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (storeIds?.length === 0) {
        const storePage = await shopAndSmileAdapter.getStores(
          employee.merchant_id,
        );

        return storePage?.stores;
      }

      const storePromises = storeIds?.map(async (storeId) => {
        const response = await shopAndSmileAdapter.getStore(
          storeId,
          employee.merchant_id,
        );
        return response;
      });
      return await Promise.all(storePromises as Array<Promise<Store>>);
    },
    {
      enabled: !!storeIds,

      onSuccess: (data) => {
        if (data) {
          setStores(data);
        } else {
          toast({
            title: 'No Stores Found',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  return (
    <>
      <Box my={4} mt={6} width={'full'}>
        {stores && stores.length === 0 && (
          <Flex justify={'center'} align={'center'} p={4}>
            <Button
              onClick={handleOpenModal}
              colorScheme="blue"
              size={'sm'}
              variant="solid">
              {' '}
              + Store
            </Button>
          </Flex>
        )}

        <Flex
          justify={'center'}
          gap={3}
          align={'center'}
          p={4}
          width={'full'}
          flexWrap={'wrap'}>
          {status === 'loading' ? (
            <Center my={4} width={'full'}>
              <Flex justify={'center'} align={'center'} gap={2}>
                <Spinner size={'sm'} />
                <Text color={'gray.400'}> Loading...</Text>
              </Flex>
            </Center>
          ) : stores?.length > 0 ? (
            stores?.map((store) => (
              <StoreCard
                key={store.id}
                store={store}
                setSelectedStore={setSelectedStore}
              />
            ))
          ) : (
            <VStack my={4} width={'full'} spacing={5}>
              <Text color={'gray.400'}>No Stores Found for user</Text>
            </VStack>
          )}
        </Flex>

        <ModalComponent isOpen={isOpen} onClose={onClose}>
          <NewStore onClose={onClose} />
        </ModalComponent>
      </Box>
    </>
  );
};

export default StoreDisplay;

import { Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const Loader = ({ message }: { message?: string }) => {
  // Define an array of messages to display
  const messages = [
    'Please Wait...',
    'Loading your data...',
    'Almost there...',
    'Fetching resources...',
    'Preparing everything for you...',
  ];

  // State to keep track of the current message index
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    // Set the interval duration (e.g., 3000 milliseconds = 3 seconds)
    const intervalDuration = 3500;

    // Function to update the message index
    const updateMessage = () => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    };

    // Initialize the interval
    const intervalId = setInterval(updateMessage, intervalDuration);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [messages.length]);

  return (
    <VStack
      align="center"
      justify="center"
      width="100%"
      height="100%"
      bg="lightBackground"
      color="black"
      p={1}>
      <Text color="black" fontSize="md" fontWeight="black">
        {message ? message : messages[currentMessageIndex]}
      </Text>
      <Spinner thickness="4px" speed="0.65s" color="black" size="xl" />
    </VStack>
  );
};
